
import React, { useEffect, useState } from 'react';

import { authHeader } from 'src/_helpers/auth-header';
import { 
  FormControl, 
  FormLabel, 
  Box, 
  FormErrorMessage ,
  Flex,
} from '@chakra-ui/react';
import { SearchFieldComponent } from 'src/_components/Forms/SearchFieldComponent';
import { FormattedMessage } from 'react-intl';
import { API_URLS } from 'src/_config';

import { useIntl } from 'react-intl';

const formatOptionLabel = ({value, label, data}) => {
  if (value?.length === 0 || value === '0' || value === 0){
    return(
      <Flex align="center">
        <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
      </Flex>
    )
  } else {
    return(
      <Flex align="center">
        <Flex direction="column">
          <Box fontFamily="Proxima Nova" mr={5}>
            {`${data?.email} | `} 
            <FormattedMessage 
                id={'format.fullName'}
                defaultMessage={'{givenName} {familyName}'}
                values={{givenName: data?.first_name, familyName: data?.last_name}}
            />
            </Box>
        </Flex>
      </Flex>
    )
  }
}

export const DentistSearch = ({label, input, onSelect, isInvalid, error, country=null, disabled=false, placeholder=null}) => {
  const {formatMessage} = useIntl();
  const [ currentValue, setCurrentValue ] = useState();

  useEffect(() => {
    setCurrentValue(input.value);
  } , [input.value])


  const onChange = (val) => {
    setCurrentValue(val);
    onSelect(val);
  }

  //const onInputChange = (val) => {
  //}

  const handleOptionsLoading = (input) => {
    if (!input){
      return Promise.resolve({ options: []})
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
    let url = API_URLS.searchAdminDentists.url+`?q=${input}`;
    if (country){
      url = url + `&country=${country}`
    }
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj) => {
          return {value: obj.value,
                  label: obj.label,
                  data: obj,
          }
        })
        return [ ...options ];
      });
  }

  return (
    <>
      <FormControl id={input.name} isInvalid={isInvalid}>
        <FormLabel align="center" pb={1}>
          {label}
        </FormLabel>
        <SearchFieldComponent
          name={input.name}
          value = {currentValue}
          label={label}
          disabled={disabled}
          isError={isInvalid}
          intl={formatMessage({id: 'patientForm.patient.search', defaultMessage: 'Search'})}
          placeholder={placeholder}
          onBlur={input.onBlur}
          formatOptionLabel={formatOptionLabel}
          createNewValue={null}
          loadOptions={handleOptionsLoading}
          onChange={onChange}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    </>
  )

}

/**
 * 
 * convert dentist search to clinic dentist search
 * dentist field should become the id/value field
 */
export const ClinicDentistSearch = ({label, input, onSelect, isInvalid, error, country=null, disabled=false, placeholder=null, clinic=null}) => {
  const {formatMessage} = useIntl();
  const [ currentValue, setCurrentValue ] = useState();

  useEffect(() => {
    setCurrentValue(input.value);
  } , [input.value])


  const onChange = (val) => {
    setCurrentValue(val);
    onSelect(val);
  }

  //const onInputChange = (val) => {
  //}

  const handleOptionsLoading = (input) => {
    if (!input){
      return Promise.resolve({ options: []})
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
    let url = API_URLS.searchAdminDentists.url+`?q=${input}`;
    if (clinic){
      url = url + `&clinic=${clinic}`
    }
    if (country){
      url = url + `&country=${country}`
    }
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj) => {
          const origId = obj.id;
          const dentistId = obj.dentist;
          return {value: dentistId,
                  label: obj.label,
                  data: {
                    ...obj,
                    user: origId,
                    value: dentistId,
                    id: dentistId
                  },
          }
        })
        return [ ...options ];
      });
  }

  return (
    <>
      <FormControl id={input.name} isInvalid={isInvalid}>
        <FormLabel align="center" pb={1}>
          {label}
        </FormLabel>
        <SearchFieldComponent
          name={input.name}
          value = {currentValue}
          label={label}
          disabled={disabled}
          isError={isInvalid}
          intl={formatMessage({id: 'patientForm.patient.search', defaultMessage: 'Search'})}
          placeholder={placeholder}
          onBlur={input.onBlur}
          formatOptionLabel={formatOptionLabel}
          createNewValue={null}
          loadOptions={handleOptionsLoading}
          onChange={onChange}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    </>
  )

}