import { create } from 'zustand';
import { produce } from 'immer';
import { toLower } from 'lodash';

// Log every time state is changed
const log = config => (set, get, api) => config(args => {
    //console.log("  applying", args)
    set(args)
    //console.log("  new state", get())
  }, get, api)

// Turn the set method into an immer proxy
const immer = config => (set, get, api) => config((partial, replace) => {
    const nextState = typeof partial === 'function'
        ? produce(partial)
        : partial
    return set(nextState, replace)
  }, get, api)

export const useMediaStore = create(
    log(immer((set) => ({
    videoFrames: {},
    imageIndex: 0,
    videoPlayer: {},
    isDetectionShowing: true,
    isDetectionLabelShowing: false,
    loadingVideoFrames: false,
    currentVideoFrameData: {},
    extractedImages: {},
    videoUrlById: {},
    setVideoFrames: (videoFramesVal) => 
        set((state) => ({
            ...state,
            videoFrames: videoFramesVal
        })),
    setImageIndex: (imageIndexVal) => 
        set((state) => ({
            ...state,
            imageIndex: imageIndexVal
        })),
    setCurrentVideoFrameData: (currentVideoFrameData) =>
        set((state) => ({
            ...state,
            currentVideoFrameData: currentVideoFrameData
        })),
    setVideoPlayer: (videoPlayer) => 
        set((state) => ({
            ...state,
            videoPlayer: Object.assign({}, state.videoPlayer, videoPlayer)
        })),
    setLoadingVideoFrames: (loadingVideoFramesVal) => 
        set((state) => ({
            ...state,
            loadingVideoFrames: loadingVideoFramesVal
        })),
    setIsDetectionShowing: (isDetectionShowingVal) => 
        set((state) => ({
            ...state,
            isDetectionShowing: isDetectionShowingVal
        })),
    setIsDetectionLabelShowing: (isDetectionLabelShowingVal) => 
        set((state) => ({
            ...state,
            isDetectionLabelShowing: isDetectionLabelShowingVal
        })),
    setVideoUrls: (data) =>
        set((state) => ({  
            ...state,
            videoUrlById: {
                ...state.videoUrlById,
                ...data
            }
        })),
    setVideoUrlById: (id, url) =>
        set((state) => ({
            ...state,
            videoUrlById: {
                ...state.videoUrlById,
                [id]: url
            }
        })),
    clearExtractedImages: () =>
            set((state) => ({
                ...state,
                extractedImages: {}
        })),
    setExtractedImages: (category, images) =>
        set((state) => ({
            ...state,
            extractedImages: {
                ...state.extractedImages,
                [category]: images
            }
        })),
    removeImage: (category, region) => 
        set((state) => {
            category = category ? toLower(category) : category;
            const updatedCategoryImages = state.extractedImages[category].filter(
                imageObject => imageObject.region !== region // if region is not equal, return to the list
            );
            return {
                ...state,
                extractedImages: {
                    ...state.extractedImages,
                    [category]: updatedCategoryImages,
                }
            };
        }),
    addCategoryExtractedImages: (category, url) =>
        set((state) => ({
            ...state,
            extractedImages: {
                ...state.extractedImages,
                [category]: [
                    ...(state.extractedImages[category] || []),
                    url
                ]
            }
        }))
}))));