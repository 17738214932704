import { useMutation } from 'react-query';
import { queryClient } from 'src/_libs/react-query';

import { generateEvaluation } from 'src/Reports/api/services';
import { QUERY_KEY_NAMES as REPORT_QUERY_KEY_NAMES } from 'src/Reports/api/constants';

import { QUERY_KEY_NAMES } from './constants';

import { toString } from 'lodash';

const generateEvaluationApi = async ({ reportUid, data, clinic }) => {
    return generateEvaluation(reportUid, data, clinic);
}

// eslint-disable-next-line no-unused-vars
const handleError =  (reportUid, _error, _context) => {
    queryClient.setQueryData([REPORT_QUERY_KEY_NAMES.GET_REPORT, {id: toString(reportUid)}], (previousData) => {
        return {report: previousData.report};
    });
}

const onSuccess = async (reportUid, data) => {
    queryClient.setQueryData([REPORT_QUERY_KEY_NAMES.GET_REPORT, {id: toString(reportUid)}], (oldData) => {
        const newData =  {
            report: {
                ...oldData.report,
                summary: oldData.report?.summary ? oldData.report?.summary : data.evaluation
            }
        }
        return newData
    });
}

export const useGenerateEvaluation = ({ reportUid, clinic }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.GENERATE_EVALUATION,
        onSuccess: (data) => onSuccess(reportUid, data),
        onError: (error, context) => handleError(reportUid, error, context),
        mutationFn: (data) => {        
            return generateEvaluationApi({ reportUid, data, clinic });
        },
    });
}