import React from 'react';
import { Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useArchiveDentistRequest } from 'src/RequestListPage/api/archiveDentistRequest';
import ArchiveIcon from 'src/_images/icons/ArchiveIcon';
import { useAppConfig } from 'src/App/state/appConfig';

export const ArchiveConfirmationDialog = ({ isOpen, onClose, requestId, queryParams={}, onSuccess=null, useQuerySuccess=null, useQueryError=null  }) => {
    const clinic = useAppConfig(state => state.clinic?.id)
    const [ error, setError ] = React.useState(null);
    const archiveMutation = useArchiveDentistRequest({id: requestId, queryParams, clinic, config: {onSuccess: useQuerySuccess, onError: useQueryError}})
    const handleClose = () => {
      setError(null);
      onClose();
    }
  
    const handleArchive = async () => {
        try{
            const resp = await archiveMutation.mutateAsync();  
            onSuccess && onSuccess(resp);
            handleClose();
        } catch (err){
            console.error(err);
            setError(err);
        }
    }
  
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'confirmationDialog.archiveRequest.title'}
                            defaultMessage='Archive Request?'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        <FormattedMessage 
                            id={'confirmationDialog.archiveRequest.description'}
                            defaultMessage='Are you sure you want to archive the request?'
                        />
                    </Text>
                </ModalBody>
                <Flex w={'full'} justify={'center'} align={'center'}>
                    {error && <Text color="red">{error.message}</Text>}
                </Flex>
                <ModalFooter pb={'20px'}>
                    <Button 
                        isLoading={archiveMutation.isLoading}
                        variant={'delete'} 
                        mr={3} 
                        onClick={handleArchive}
                        leftIcon={<ArchiveIcon color={'white'} width={18} height={18} />}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.archiveRequest.confirm'}
                            defaultMessage={'Yes, Archive'}
                        />
                    </Button>
                    <Button 
                        isDisabled={archiveMutation.isLoading}
                        variant="outlined" 
                        onClick={handleClose}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.archiveRequest.cancel'}
                            defaultMessage={'No, Close'} 
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
  }