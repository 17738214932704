import { useMutation } from 'react-query';

import { unsendReport } from './services';
import { QUERY_KEY_NAMES } from './constants';
import { queryClient } from 'src/_libs/react-query';
import { toString } from 'lodash';

const queryApi = async ({ reportUid, data, clinic }) => {
    return unsendReport(reportUid, data, clinic);
}

const handleError =  (reportUid) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {id: toString(reportUid)}], (oldData) => {
        if (oldData?.report) {
            return {report: oldData.report};
        } else {
            return undefined; // return blank
        }
    });
}

const onSuccess = async(reportUid, data) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {id: toString(reportUid)}], (oldData) => {
        if (!oldData?.report) {
            return undefined; // return blank
        }
        return {
            report: {
                ...oldData.report,
                ...data
            }
        }
    });
}

export const useUnsendReport = ({ id: reportUid, clinic, config }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.SEND_REPORT,
        onSuccess: (data) => onSuccess(reportUid, data),
        onError: (error, context) => handleError(reportUid, error, context),
        mutationFn: (data) => {        
            return queryApi({ reportUid, data, clinic });
        },
        ...config
    });
}