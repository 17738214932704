import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArchiveIcon = (props) => {
  const iconWidth = props?.width || 20;
  const iconHeight = props?.height || 20;
  return (
    <Icon 
      viewBox="0 0 20 20" 
      color={'#2E3D44'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M2 6.5L18 6.5M2 6.5L2 17.5H18L18 6.5M2 6.5L5.73333 3L14.2667 3L18 6.5" stroke="currentColor"/>
        <path d="M10 9V15M10 15L13 12.5M10 15L7 12.5" stroke="currentColor"/>
    </Icon>
  );
};
export default ArchiveIcon;
