import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';

import axios from 'axios'

import { cloneDeep } from "lodash";
import { Square, Box, Center, Flex, HStack, SimpleGrid, Text, Button, FormLabel,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
    useDisclosure } from '@chakra-ui/react';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useDentistSelectOptions } from '../SettingsPage/api/getDentistSelectOptions';
//import { UserSearch } from '../AdminPage/components/UserSearch'

import { createRecord, upload, createPatientRequest } from './api/services'
import { usePatientUploadScanStore } from './state/uploadScans';

import { convertToAPIValues } from 'src/_libs/forms';
import { ConfirmationDialog } from '../AdminPage/components/ConfirmationDialog';

import { ScanRegionTitle, UploadPreview, UploadPlaceholder, UploadThumbnail } from '../AdminPage/components/UploadComponents';
import { RECORD_TYPES } from 'src/_config';
import { ClinicDentistUserSearch } from '../Dentists/DentistSearch';
import { ClinicPatientSearch } from 'src/Patients/ClinicPatientSearch';
import { useAppConfig } from 'src/App/state/appConfig';
import { CheckboxComponent } from 'src/_components/Forms/CheckBoxComponent';
import { useAuth } from 'src/_libs/auth';
import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';

const formatInitValues = (values) => {
    // format select search fields
    if (!values?.user){
        return values;
    }
    return {
        ...values,
        user: {
            ...values?.user,
            data: values?.user
        }
    }
}

const RequestCreatedDialog = ({ isOpen, onClose, name, requestType }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'requestCreatedDialog.title'}
                            defaultMessage='Success!'
                        />
                    </Center>
                </ModalHeader>
                <ModalBody pt={'30px'} pb={'20px'}>
                        <FormattedMessage 
                            id={'requestCreatedDialog.description'}
                            defaultMessage='User Request was created successfully!'
                        /><br /><br />
                        <FormattedMessage 
                            id={'requestCreatedDialog.request.type'}
                            defaultMessage='Type: {type}'
                            values={{type: requestType }}
                        /><br />
                        <FormattedMessage 
                            id={'requestCreatedDialog.request.name'}
                            defaultMessage='Name: {name}'
                            values={{name: name }}
                        />
                </ModalBody>
                <ModalFooter pb={'20px'}>
                    <SimpleGrid columns={[2]} spacing={4}>
                        <div />
                        <Button mr={3} onClick={onClose}>
                            <FormattedMessage 
                                id={'requestCreatedDialog.close'}
                                defaultMessage={'Close'}
                            />
                        </Button>
                    </SimpleGrid>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
 

const ScanRegion = ({region, selectedFile}) => {
    const removeRegion = usePatientUploadScanStore(state => state.removeRegion);
    const setRegion = usePatientUploadScanStore(state => state.setRegion);

    const fileInputRef = React.useRef(null);
    const [dragOver, setDragOver] = React.useState(false);

    const handleFileInputClick = () => {
        // Trigger the file input click event
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        if (!dragOver) setDragOver(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        processFile(file);
    };


    const handleRemove = () => {
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      //setPreviewUrl('');
      // Clear the selected file state
      //setSelectedFile(null);
      if (region != null){
          removeRegion(region)
      }
    };

    const handleFileChange = (event) => {
        processFile(event.target.files[0]);
    };

    const processFile = (file) => {
        if (!file) {
            return;
        }
        //if (selectedFile?.previewUrl) {
        //    URL.revokeObjectURL(selectedFile?.previewUrl);
        //}
        setRegion(region, file)
    };

    const boxSize = '110px';
    const innerBoxSize = '100px';

    return (
        <Flex  direction={'column'} align={'center'}>
            <Flex>
                <Text
                    fontSize={'16px'}
                    color={'#7B8887'}
                    textTransform={'capitalize'}
                >
                    <ScanRegionTitle region={region} /> 
                </Text>
            </Flex>
            <Square 
                mt={'15px'}
                borderRadius={'10px'}
                pos={'relative'}
                size={boxSize}
                align={'center'}
                bg={dragOver ? '#35BE9D' : 'transparent'}
                border={'2px dashed #6B8A99'}
            >
                <Box w={['full']} pos={'absolute'}>
                    <input 
                        type="file" 
                        ref={fileInputRef}
                        hidden 
                        onChange={handleFileChange} 
                        style={{display: 'none'}}
                        accept="video/*" 
                    />
                    { selectedFile ? (
                        <UploadPreview
                            selectedFile={selectedFile}
                            innerBoxSize={innerBoxSize}
                            handleRemove={handleRemove}
                        />
                    ) : (
                        <UploadPlaceholder 
                            innerBoxSize={innerBoxSize}
                            dragOver={dragOver}
                            handleDragOver={handleDragOver}
                            handleDragEnter={handleDragEnter}
                            handleDragLeave={handleDragLeave}
                            handleDrop={handleDrop}
                            handleFileInputClick={handleFileInputClick}
                        />
                    )}
                </Box>

            </Square>
        </Flex>
    )
}

const UploadSubmitButton = ({isLoading, handleUploadClick, onComplete, isDisabled, isCompleted}) => {
    useEffect(() => {
        if (isCompleted){
            onComplete();
        }
    }, [isCompleted])


    const handleClick = () => {
        if (isCompleted){
            onComplete()
        } else {
            if (!isLoading){
                handleUploadClick()
            }
        }
    }
    return (
        <Button
            isDisabled={isDisabled}
            isLoading={!isCompleted && isLoading} 
            onClick={handleClick}
        >
            { isCompleted ? (
                    <FormattedMessage 
                        id="adminPage.form.finish.button.label"
                        defaultMessage="Next"
                    />
                ) : (
                    <FormattedMessage 
                        id="adminPage.form.upload.button.label"
                        defaultMessage="Upload"
                    />
                )
            }
        </Button>
    )
}

const checkUploadComplete = (isUploading, region0, region1, region2, region3) => {
    if (isUploading && 
        ((region0?.previewUrl != null && region0?.uploadCompleted == true || region0?.previewUrl == null) &&
        (region1?.previewUrl != null && region1?.uploadCompleted == true || region1?.previewUrl == null) &&
        (region2?.previewUrl != null && region2?.uploadCompleted == true || region2?.previewUrl == null) &&
        (region3?.previewUrl != null && region3?.uploadCompleted == true || region3?.previewUrl == null) )
    ) {
        return true;
    } else {
        return false;
    }
}

const getRegionsToUpload = (region0, region1, region2, region3) => {
    return [region0, region1, region2, region3].filter((region) => {
        return region?.previewUrl != null;
    })
}

const getUploadRegionIndexes = (region0, region1, region2, region3) => {
    const regions = [region0, region1, region2, region3];
    const result = [];
    for (let index in regions){
        if (regions[index]?.previewUrl != null){
            result.push(regions[index].extraData?.region)
        }
    }
    return result;
}

const UploadsForm = ({patientId, onError, onClose, onSuccess}) => {
    const currentRecordRef = React.useRef(null);

    const isUploading = usePatientUploadScanStore(state => state.isUploading);
    const clearUploads = usePatientUploadScanStore(state => state.clearUploads);
    const setIsUploading = usePatientUploadScanStore(state => state.setIsUploading);  
    const updateRegion = usePatientUploadScanStore(state => state.updateRegion);
    const region0 = usePatientUploadScanStore(state => state.region0);
    const region1 = usePatientUploadScanStore(state => state.region1);
    const region2 = usePatientUploadScanStore(state => state.region2);
    const region3 = usePatientUploadScanStore(state => state.region3);

    const colSpacing = 4;

    const handleRecordError = () => {  
        clearUploads();
        onError && onError();
        onClose();
    }

    const handleUpload =  async () => {
        const currentRegions = getRegionsToUpload(region0, region1, region2, region3);
        const uploadRegionIndex = getUploadRegionIndexes(region0, region1, region2, region3); // to get region after uplading through axios.all
        if (region0 || region1 || region2 || region3) {
                try {
                    // create record here with API
                    const data = {
                        "patient": patientId,
                        "type": RECORD_TYPES.FULLSCAN,
                        "extra_data": null
                    }
                    const recordResp = await createRecord(data);
                    currentRecordRef.current = recordResp.id;
                } catch(err){
                    console.log("UPLOAD ERROR: ", err)
                    handleRecordError();
                    return;
                }
                let uploadList = currentRegions.map((selectedFile, index) => {
                    return  upload(
                                selectedFile?.data, 
                                currentRecordRef.current, 
                                (event) => {
                                    // HACK set final progress to 95 because there is a delay between progress and finished upload
                                    updateRegion(uploadRegionIndex[index], {progress: Math.round((event.loaded) / event.total), isUploading: true})
                                }, 
                                selectedFile.extraData
                    )
                })
                axios.all(uploadList)
                    .then( axios.spread((...responses) => {
                        responses.map((resp, index) => {
                            updateRegion(uploadRegionIndex[index], {uploadData: resp.data, isUploading: false, uploadCompleted: true})
                        })
                    })).catch(errs => {
                        console.log(errs);
                        // TODO errs lenght or check if object
                        if (errs.length > 0){
                            errs.map((err, index) => {
                                updateRegion(uploadRegionIndex[index], {uploadError: err})
                            })
                        } else if (typeof errs == typeof {}){
                            updateRegion(0, {uploadError: JSON.stringify(errs)})
                        } else {
                            updateRegion(0, {uploadError: errs})
                        }
                    })
        }
    }

    const handleUploadClick = () => {
        if (isUploading){
            return
        }
        setIsUploading(true)
        handleUpload()
    }

    const handleFinish = () => {
        // closes in parent component, so just send the record id
        onSuccess && onSuccess(currentRecordRef.current);
    }

    return (
        <Box position={'relative'} height={'300px'} overflow={'auto'}>
            <SimpleGrid>
                { /*<div className={'loading'}><LoadingEllipses /></div>*/}
                <HStack w={['full']} minW={`650px`} px={['40px']} py={['30px']} spacing={'25px'}>
                {
                    [region0, region1, region2, region3].map((selectedFile, region) => {
                        return (
                            <ScanRegion region={region} selectedFile={selectedFile} key={region} />
                        )
                    })
                }
                </HStack>
            </SimpleGrid>
            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                <Button 
                    variant={'outline'} 
                    onClick={onClose} 
                    isDisabled={isUploading || (region0?.uploadCompleted && region1?.uploadCompleted && region2?.uploadCompleted && region3?.uploadCompleted)}
                >
                    <FormattedMessage 
                        id="adminPage.form.cancel.button.label"
                        defaultMessage="Cancel"
                    />
                </Button>
                <UploadSubmitButton 
                    isCompleted={checkUploadComplete(isUploading, region0, region1, region2 ,region3)}
                    isDisabled={
                        !region0?.data &&
                        !region1?.data &&
                        !region2?.data &&
                        !region3?.data
                    }
                    onComplete={handleFinish}
                    isLoading={isUploading} 
                    handleUploadClick={handleUploadClick}
                />
            </SimpleGrid>
        </Box>
    )
}

const UploadsFormModal = ({patientId, onSuccess, onClose, isOpen}) => {
    return (
        <Modal isCentered isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent minW={'800px'}>
                <ModalHeader>
                    <FormattedMessage 
                        id={'adminPage.form.uploadScans.title'}
                        defaultMessage='Upload Scans'
                    />
                </ModalHeader>
                <ModalBody>
                    <UploadsForm 
                        patientId={patientId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const ScanThumbnails = ({region0, region1, region2, region3}) => {
    const innerBoxSize = '50px'
    return (
        <HStack spacing={'5px'} ml={'20px'}>
            <UploadThumbnail selectedFile={region0} innerBoxSize={innerBoxSize} />
            <UploadThumbnail selectedFile={region1} innerBoxSize={innerBoxSize} />
            <UploadThumbnail selectedFile={region2} innerBoxSize={innerBoxSize} />
            <UploadThumbnail selectedFile={region3} innerBoxSize={innerBoxSize} />
        </HStack>
    )
}

export const CreateRequestForm = ({
    onSuccess,
    onClose,
    initValues = {},
  }) => {
    const { user } = useAuth(); 
    const selectedClinic = useAppConfig(state => state.clinic); 
    const { data : requestTypesData, isLoading : requestTypesIsLoading, isFetching : requestTypesIsFetching } = useDentistSelectOptions({'option': 'request_types'});
    const { isOpen: isUploadFormOpen, onClose: onUploadFormClose, onOpen: onUploadFormOpen } = useDisclosure();
    const { isOpen: isCreatedDialogOpen, onClose: onCreatedDialogClose, onOpen: onCreatedDialogOpen } = useDisclosure();
    const [recordId, setRecordId] = React.useState(null);
    const { formatMessage } = useIntl();
    const clearUploads = usePatientUploadScanStore(state => state.clearUploads);
    const region0 = usePatientUploadScanStore(state => state.region0);
    const region1 = usePatientUploadScanStore(state => state.region1);
    const region2 = usePatientUploadScanStore(state => state.region2);
    const region3 = usePatientUploadScanStore(state => state.region3);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues({...valuesCopy, record: recordId});
        try{
            await createPatientRequest(convertedValues);
            onCreatedDialogOpen();
            clearUploads();
            return null;
        } catch (err) {
            return {
                ...err,
            };
        }
    }

    const isEditForm = initValues.id ? true : false;

    const onUploadSuccess = (values) => {
        setRecordId(values?.value ? values?.value : values)
        onUploadFormClose();
    }

    const handleCreatedDialogClose = () => {   
        // close this form
        onCreatedDialogClose();
        onSuccess && onSuccess();
    }

    if (user?.is_staff && selectedClinic?.id == null){
        // need to warn user that they should select a clinic first. just staff members?
        return (
            <Box>
                <Flex mt={'30px'}>
                    <Text>
                        <FormattedMessage 
                            id={'patientRequest.form.noclinicselected'}
                            defaultMessage={'Please select a clinic to manage first'}
                        />
                    </Text>
                </Flex>
                <SimpleGrid columns={[3]}  mt={10}>
                    <div />
                    <div />
                    <Button onClick={onClose}>
                        <FormattedMessage 
                            id="adminPage.form.close.button.label"
                            defaultMessage="Close"
                        />
                    </Button>
                </SimpleGrid>
            </Box>
        )
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={formatInitValues(initValues)} // format dentist value to add data field for select option componentf
            render = {
                ({
                    handleSubmit,
                    submitting,
                    values
                }) => {
                    return (
                            <Box as={'form'} onSubmit={handleSubmit}>
                                <RequestCreatedDialog 
                                    isOpen={isCreatedDialogOpen}
                                    onClose={handleCreatedDialogClose}
                                    name={values?.patient?.label}
                                    requestType={values?.type?.label}
                                />
                                <SimpleGrid columns={[1]} spacing={colSpacing} spacingY={'5px'} mt={rowSpacing} mb={'30px'}>
                                    <Field name="patient">
                                        {({input, meta}) => (
                                            <ClinicPatientSearch
                                                clinic={selectedClinic?.id}
                                                input={input}
                                                meta={meta}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                label={formatMessage({id: 'adminPage.form.field.patient.label', defaultMessage: 'Patient'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.patient.placeholder', defaultMessage: 'Search for a patient'})}
                                                onSelect={(val) => {
                                                    input.onChange(val);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="type" validate={required} >
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isDisabled={isEditForm}
                                                isLoading={requestTypesIsFetching || requestTypesIsLoading}
                                                label={formatMessage({id: 'adminPage.form.field.request_type.label', defaultMessage: 'Request Type'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.request_type.label', defaultMessage: 'Request Type'})}
                                                input={input}
                                                selectOptions={requestTypesData?.options}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} colSpacing={colSpacing} rowSpacing={0} mt={rowSpacing}>
                                    <FormLabel>
                                        <FormattedMessage 
                                            id={'adminPage.form.uploads.label'}
                                            defaultMessage={'Scans'}
                                        />
                                    </FormLabel>
                                    <Flex align={'center'}>
                                        <Button 
                                            variant={'outline'} 
                                            onClick={onUploadFormOpen} 
                                            isDisabled={
                                                submitting || !values?.patient || (
                                                    region0?.uploadCompleted && region1?.uploadCompleted && region2?.uploadCompleted && region3?.uploadCompleted
                                                )}
                                        >
                                            <FormattedMessage
                                                id="adminPage.form.upload.button.label"
                                                defaultMessage="Start Uploads"
                                            />
                                        </Button>
                                        <ScanThumbnails
                                            region0={region0}
                                            region1={region1}
                                            region2={region2}
                                            region3={region3}
                                        />
                                    </Flex>
                                    <UploadsFormModal
                                        patientId={values?.patient?.value}
                                        onSuccess={onUploadSuccess}
                                        onClose={onUploadFormClose}
                                        isOpen={isUploadFormOpen}
                                    />
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="run_detections">
                                        {({input, meta}) => (
                                            <CheckboxComponent
                                                label={formatMessage({id: 'adminPage.form.field.patient.run_detections.label', defaultMessage: 'Run AI Detection on Uploads'})}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                colorScheme={'brand'}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="clinic_dentist">
                                        {({input, meta}) => (
                                            <ClinicDentistUserSearch
                                                clinic={selectedClinic?.id}
                                                input={input}
                                                meta={meta}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                label={formatMessage({id: 'adminPage.form.field.request_dentist.label', defaultMessage: 'Dentist (Optional)'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.accountrole.request_dentist.placeholder', defaultMessage: 'Search for a dentist'})}
                                                onSelect={(val) => {
                                                    input.onChange(val);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
    
                                <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                    <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                        <FormattedMessage 
                                            id="adminPage.form.cancel.button.label"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button type="submit" 
                                        isLoading={submitting} 
                                        isDisabled={(recordId == null) || (values?.patient?.value == null) || (values?.type?.value == null) }
                                    >
                                        <FormattedMessage 
                                            id="adminPage.form.submit.button.label"
                                            defaultMessage="Submit"
                                        />
                                    </Button>
                                </SimpleGrid>
    
                            </Box>
                    )
                }
            }
        />
    )
}

export const RequestForm = ({
    onSuccess,
    onClose
}) => {
    // select user then create request
    const { isOpen, onOpen, onClose: onConfirmClose } = useDisclosure();

    const handleRequestFormClose = () => {
        onOpen();
    }

    const handleOnCreateRequestSuccess = () => {
        onSuccess();
    }

    return (
        <>
            <ConfirmationDialog isOpen={isOpen} onClose={onConfirmClose} handleClose={onClose} />

            <CreateRequestForm  
                onSuccess={handleOnCreateRequestSuccess}
                onClose={handleRequestFormClose}
            />
        </>
    )

}