/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import { Form, Field } from 'react-final-form';
import { 
    Flex, Box, Text, HStack, VStack, FormLabel, SimpleGrid, Select, Button, IconButton,
    Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'

import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import PaginationNext from 'src/_images/icons/PaginationNext';

import { useCSVReader } from 'react-papaparse';
import { FormattedMessage, useIntl } from 'react-intl';

import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { UserSearch } from './UserSearch';
//import { useGenerateLLM } from 'src/LLM/api/checkCSVUserProfileHeaders';

// create component where admin user can import csv files to add user profiles for a user
const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    return (
        <HStack spacing={3} >
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationPrevious />} 
                isDisabled={!canPreviousPage}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <Select 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </Select>
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}

const PageTable = ({headers, data, isLoading, onEditRow}) => {
    const PAGE_SIZE = 100;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })
    const { formatMessage } = useIntl();
    const columns = React.useMemo(
        () => [
            {
                header: 'Name',
                id: 'name',
                accessorKey: 'name',
            },
            {
                header: 'First Name',
                id: 'first_name',
                accessorKey: 'first_name',
            },
            {
                header: 'Last Name',
                id: 'last_name',
                accessorKey: 'last_name',
            },
            {
                header: 'Date of Birth',
                id: 'date_of_birth',
                accessorKey: 'date_of_birth',
            },
            {
                header: 'ID',
                id: 'external_id',
                accessorKey: 'external_id',
            },
            {
                header: 'Guardian Name',
                id: 'guardian_name',
                accessorFn: row => row.extra_data?.guardian_name,
            },
            {
                header: 'Class',
                id: 'class_level',
                accessorFn: row => row.extra_data?.class_level,
            },
            {
                header: 'Class Name',
                id: 'class_name',
                accessorFn: row => row.extra_data?.class_name,
            },
            {
                header: 'Consent',
                id: 'consent',
                accessorFn: row => row.extra_data?.consent,
            }
        ],
        []
    )

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })


    const handleRowClick = (row) => {
        onEditRow(row?.id)
    }

    return (
        <>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} onClick={() => handleRowClick(row.original)} _hover={{cursor: 'pointer', bg: '#F7F7F7'}}>
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Flex w="full" mt={'50px'} justify={'flex-end'}>
                <Pagination 
                    isLoading={isLoading}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageIndex={table.getState().pagination.pageIndex}
                    total={table.getPageCount()}
                    nextPage={table.nextPage}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                />
            </Flex>
        </>
    )
}

const UserProfileFile = ({
    onSubmit, isSubmitting
}) => {
    const { CSVReader } = useCSVReader();
    //const llmMutation = useGenerateLLM({response_type: 'csv_user_profile_headers', clinic: null, onSuccess: null, handleError: null});
    const [csvData, setCsvData] = useState([]);
    const REQUIRED_HEADERS = [
        'name', 'date_of_birth', 'external_id'
    ]
    const OPTIONAL_HEADERS = [
        'guardian_name', 'class_level', 'class_name', 'consent'
    ]

    const PRE_HEADERS = [
        'name', 'date_of_birth', 'external_id', 'first_name', 'last_name', 'guardian_name', 'class_level', 'class_name', 'consent'
    ]
  
    const isCSVHeader = false;

    const clearData = () => {
        setCsvData([]);
    }

    const parseHeader = (header) => {
        //parse header
        // convert header to object
        // set header to true


        console.log("header ", header)
        return header;
    }

    const handleOnUploadAccepted = (results) => {
        const newData = []
        // if header is incorrect, then return error. 

        if (isCSVHeader == false){
            const topHeader = parseHeader(results.data[0])
            newData[0] = topHeader
            results.data.slice(1).forEach((row) => {
                const newRow = {}
                row.forEach((cell, index) => {
                    newRow[topHeader[index]] = cell
                })
                newData.push(newRow)
            })
        }

        // set header and set data



        setCsvData(newData); // Parsed data as an array of arrays or objects
    };

    const handleApiSubmit = () => {
      // Here you could send `csvData` to your API
      console.log("Ready to send data:", csvData);
      // Example: axios.post('/your-api-endpoint', csvData);
      //onSubmit
    };

    return (
      <Box py={'30px'}>
        <FormLabel align="center" pb={1}>
            <FormattedMessage 
                id={'adminPage.form.importprofiles.uploadCSV'}
                defaultMessage={'Upload CSV File'}
            />
        </FormLabel>
        <VStack align={'flex-start'} pb={'10px'}>
            <Text>
                <FormattedMessage
                    id={'adminPage.form.importprofiles.uploadCSV.descriptionRequired'}
                    defaultMessage={'Upload a CSV file with the following required headers: {headers}'}  
                    values={{headers: REQUIRED_HEADERS.join(', ')}}
                />
            </Text>
            <Flex  direction={'column'}>
                <Text>
                    <FormattedMessage
                        id={'adminPage.form.importprofiles.uploadCSV.descriptionHeaders'}
                        defaultMessage={'Other Accepted Headers:'}  
                    /><br />
                    <FormattedMessage
                        id={'adminPage.form.importprofiles.uploadCSV.headersFormatted'}
                        defaultMessage={'{headers}'}  
                        values={{headers: PRE_HEADERS.join(', ')}}
                    />
                </Text>
            </Flex>
        </VStack>
        <Flex justify={'space-between'}>
            <CSVReader
                onUploadAccepted={handleOnUploadAccepted}
                config={{ header: isCSVHeader }}
            >
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                <VStack>
                    <Flex justify={'space-between'}>
                        <div {...getRootProps()} style={{ border: '1px solid #ccc', padding: '10px', cursor: 'pointer' }}>
                            {acceptedFile ? acceptedFile.name : "Click to select CSV"}
                        </div>
                        {acceptedFile && (
                            <Button {...getRemoveFileProps()} style={{ margin: '10px' }}>
                                <FormattedMessage 
                                    id={'adminPage.form.importprofiles.removeFile'}
                                    defaultMessage={'Remove'}
                                />
                            </Button>
                        )}
                    </Flex>
                    <ProgressBar />
                </VStack>
                )}
            </CSVReader>
            <HStack>
                {
                    csvData.length > 0 ? (
                        <Button
                            variant={'deleteOutline'}
                            onClick={clearData}
                            
                        >
                            <FormattedMessage
                                id={'adminPage.form.importprofiles.clearData'}
                                defaultMessage={'Clear Data'}
                            />
                        </Button>
                    ) : (
                        <></>
                    )
                }
                <Button 
                    onClick={handleApiSubmit} 
                    variant={'outlined'} 
                    isLoading={isSubmitting} 
                    isDisabled={!csvData.length}
                >
                    <FormattedMessage
                        id={'adminPage.form.importprofiles.submit'}
                        defaultMessage={'Upload Profiles'}
                    />
                </Button>
            </HStack>
        </Flex>
  
        {/* Display parsed CSV data (optional) */}
        {
          <Box mt={'30px'}>
            <PageTable 
                headers={csvData ? csvData[0] : []}
                data={csvData ? csvData.slice(1) : []}
                isLoading={false}
                onEditRow={null}
            />
          </Box>
        }
      </Box>
    );

}

export const UserProfileImport = ({
    onSuccess,
    onClose
}) => {
    const { formatMessage } = useIntl();
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = (values) => {
        console.log(values);
    }

    return (
        <Form
            onSubmit={handleSubmit}
            render = {
                ({
                    handleSubmit,
                    submitting
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Text>
                                    <FormattedMessage
                                        id="adminPage.form.importprofiles.select_user.description"
                                        defaultMessage="Select a patient user to add user profiles to."
                                    />
                                </Text>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="user">
                                    {({input, meta}) => (
                                        <UserSearch   
                                            input={input}
                                            meta={meta}
                                           isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                            error={getFinalFormMetaError(meta)}
                                            label={formatMessage({id: 'adminPage.form.field.user.label', defaultMessage: 'User'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.user.placeholder', defaultMessage: 'Search for a user'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <UserProfileFile onSubmit={handleSubmit} isSubmitting={submitting} />
                        </Box>
                    )
                }
            }
        />
    )
};
