import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchAppFeatures } from './services';

export const QUERY_KEY_NAME = 'Dentist_Features';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async () => {
    return fetchAppFeatures()
}

export const useAppFeatures = () => {
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME, {}],
        queryFn: () => queryRequest(), // in brackets because im parsing the object in queryRequest
      });
}