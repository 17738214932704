/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import { Text, Modal, ModalOverlay, ModalCloseButton, ModalContent, ModalHeader, ModalBody, Spinner, Progress } from '@chakra-ui/react';
import { Box, Flex, Button } from '@chakra-ui/react';
import { CloseIcon } from 'src/_images/icons/CloseIcon';
import { useReportDetails } from '../api/getReport';
import { getCategoryColor } from './CategoryDot';
import { useMediaStore } from '../state/media';
import { toUpper } from 'lodash';
import { useAppConfig } from 'src/App/state/appConfig';
import { FormattedMessage } from 'react-intl';

const ExtractedImageToothLabel = ({toothNumber, catColor, size=16}) => {
    const initTop = -5;
    const initLeft = -4;
    const initSize = size;
    const spacing = 3

    const individualToothNumbers = toothNumber.split(",")

    return (
        <>
            {
                individualToothNumbers.map((toothNumber, index) => (
                    <Flex 
                        key={`${index}_${toothNumber}`}
                        pos={'absolute'} top={`${initTop}px`} left={`${initLeft + (initSize + spacing) * index}px`} 
                        align={'center'} justify={'center'}
                        w={`${size}px`} h={`${size}px`}
                        fontFamily={'DM Sans'}
                        borderRadius={'50%'} bg={catColor} color={'white'}
                        zIndex={1} 
                    >
                        <span
                            style={{
                                fontSize: '5.9pt'
                            }}
                            className={'toothImageLabel'}
                        >
                            {toothNumber}
                        </span>
                    </Flex>
                ))
            }
        </>
    )
}

const ExtractedImageRemoveButton = ({onClick, size=16}) => {
    const initTop = -5;
    const initRight = -5;

    return (
        <Flex
            className={'pdf-displayNone'}
            pos={'absolute'}
            top={`${initTop}px`}
            right={`${initRight}px`}
            w={`${size}px`} h={`${size}px`}
            bg={'#405159'}
            borderRadius={'50%'}
            justify={'center'}
            align={'center'}
            zIndex={2}
            onClick={onClick}
            _hover={{
                cursor: 'pointer'
            }}
        >
            <CloseIcon width={6} height={6} color={'#F7F7F7'} />
        </Flex>
    )
}

export const ExtractedImagesCategory = ({category, extractedImages, innerBoxSize='80px'}) => {
    //const extractedImages = useMediaStore((state) => state.extractedImages);
    const removeImage = useMediaStore((state) => state.removeImage);
    const catColor = getCategoryColor(category);
    // Make a copy of the original data and sort it by the 'region' key
    //const sortedData = [...(extractedImages|| [])].sort((a, b) => Number(b.region) - Number(a.region) );

    const handleRemoveImage = (region) => {
        removeImage((category || "").toUpperCase().trim(), region);
    }

    const sortedRegions = Object.keys((extractedImages || {})).sort((a, b) => a.substring(0,2) - b.substring(0,2));
    return (
        <Flex flexWrap={'wrap'} mt={'10px'}>
            {(sortedRegions || []).map((region, index) => (
                <Flex pos={'relative'} key={index} borderRadius={'10px'} w={innerBoxSize} h={innerBoxSize} mr={'10px'} mb={'5px'}>
                    <ExtractedImageToothLabel 
                        toothNumber={region} 
                        catColor={catColor} 
                    />
                    <ExtractedImageRemoveButton 
                        onClick={() => handleRemoveImage(region)} 
                    />
                    <img 
                        style={{
                            objectFit: 'cover',
                            borderRadius: '10px',
                            width: innerBoxSize,
                            height: innerBoxSize,
                            position: 'absolute'
                        }} 
                        src={extractedImages[region]} 
                        crossOrigin="anonymous" 
                        alt={`Extracted Frame`} 
                    />
                </Flex>
            ))}
        </Flex>
    );
}

const ExtractedImages = () => {
    const extractedImages = useMediaStore((state) => state.extractedImages);
    return (
        <Box>
            {
                Object.entries(extractedImages).map(([category, images]) => (
                    <Box key={category}>
                        <ExtractedImagesCategory category={category} extractedImages={images} />
                    </Box>
                ))
            }
        </Box>
    )
}

async function seekToTime(video, timestamp, region ){
    const videoDiff = 0.1;
    return new Promise((resolve, reject) => {
        video.addEventListener('canplaythrough', () => {
                video.currentTime = timestamp;
        });
        video.addEventListener('timeupdate', function handleTimeUpdate() {
            if ((Math.abs(video.currentTime - timestamp) < videoDiff)) { 
                video.removeEventListener('timeupdate', handleTimeUpdate);
                video.pause();
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    let url = null;
                    try{
                        url = URL.createObjectURL(blob);
                    } catch (e) {

                        reject(e);
                        return;
                    }
                    resolve({ url, region });  // Resolve with all necessary details
                }, 'image/png');
            }
        });
    });
};

/**
 * Extract the Frames of each region needed for the report
 * @param {*} param0 
 * @returns 
 */
export const FrameExtractor = ({ videoData, report, nextPage, onClose, innerBoxSize='80px' }) => {
    // videoUrl, timestamp, category color, tooth number
    // input should be grouped by videourl for linear extraction
    // output should be grouped by category for display (usemediastore)
    const videoRefs = useRef([]);
    const videoUrlById = useMediaStore((state) => state.videoUrlById);
    const addCategoryExtractedImages = useMediaStore((state) => state.addCategoryExtractedImages);
    const clearExtractedImages = useMediaStore((state) => state.clearExtractedImages);
    const canvasRef = useRef(null);
    const [processingText, setProcessingText ] = useState("Processing");
    const [progress, setProgress] = useState(0);
    const [images, setImages] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        const processVideos = async () => {
            if (!canvasRef.current) return;
            setIsProcessing(true);  // Start processing
            clearExtractedImages();  // Clear previous images
            let totalFramesFromReport = 0;
            //let totalFramesToProcess = Object.values(videoData).reduce((sum, currentArray) => sum + currentArray.length, 0);
            let processedFrames = 0;
            const videoDataByCategory = {};

            const videoFramestoExtract = {}; // {videoId: []}
            // { videoId: { region: videoFrameData , { region }}}

            // need the videoFrames to extract
            // get the regions and the videoFrames

            for (const block of (report?.report_data?.blocks || [])) {
                let videoFrames = block?.data?.videoFrames;
                let regions = block?.data?.regions;

                //console.log("block ", block)
                //console.log("regions ", regions)
                for (const region of regions) {
                    // for each region, find it in videoFrames and add it to frames to extract
                //console.log("videoFrames ", videoFrames)
                    for (const [ frameRegion, frameData ] of videoFrames) {
                        // go through the first one that matches the region, add it, then break
                        //console.log("frameRegion ", frameRegion)
                        //console.log("frameData ", frameData)
                        if (!videoFramestoExtract[frameData?.id]){
                            videoFramestoExtract[frameData?.id] = {};
                        }
                        if (region === frameRegion ) {
                            if (!videoFramestoExtract[frameData?.id][region]) { //frameData?.id is the videoId
                                videoFramestoExtract[frameData?.id][region] = [{frameData, ...block?.data}];
                            } else {
                                videoFramestoExtract[frameData?.id][region].push({frameData, ...block?.data});
                            }
                            totalFramesFromReport += 1;
                            break;
                        }
                    }
                }
            };

            // from videoData: create an obnject of category, then region
            for (const [videoId, data] of Object.entries(videoFramestoExtract)){
                let video = document.createElement('video');
                video.crossOrigin = 'anonymous';
                video.crossorigin = 'anonymous';
                console.log(video)
                video.src = videoUrlById[videoId];
                video.load();  // Start loading the video
                videoRefs.current.push(video);
                // for each data, for each region
                for (const [eachRegion, eachData] of Object.entries(data)) {
                    for (const data of eachData) {
                        let res = null
                        try{
                            res = await seekToTime(video, data?.frameData?.playedSeconds, eachRegion);
                        } catch (e) {
                            console.error("error ", e)
                            res = null;
                            continue;
                        }
                        const { url, region } = res;
                        addCategoryExtractedImages(data?.category, {url, region});
                        processedFrames++;
                        setProcessingText(`Processing frame ${processedFrames} out of ${totalFramesFromReport}`)
                        setProgress(processedFrames / totalFramesFromReport * 100)
                    }
                }
            };

            /*
             * 
            686: [{  category: "CARIES",
                frameData: {id: 686, uid: '7502ae28-d065-4097-b194-8d9b76cac967', loaded: 0.9814812964812965, played: 0.611111111111111, srcUrl: 'https://bd-plover-staging-ap.s3.amazonaws.com/upl/…64a5dff4ce7e10e9b158da26ecdf075efb64b4e169f6a3fae', …},
                region: "26",
                timestamp: 1.1011
            }]
            // old video extraction
            for (const [videoId, data] of Object.entries(videoData)){
                const video = document.createElement('video');
                video.crossOrigin = 'anonymous';
                video.src = videoUrlById[videoId];
                video.load();  // Start loading the video
                videoRefs.current.push(video);
                for (const eachData of data) {
                    //console.log("eachData ", eachData)
                    const { url, region } = await seekToTime(video, eachData?.frameData?.playedSeconds, eachData?.region);
                    addCategoryExtractedImages(eachData?.category, {url, region});
                    processedFrames++;
                    setProcessingText(`Processing frame ${processedFrames} out of ${totalFramesToProcess}`)
                    setProgress(processedFrames / totalFramesToProcess * 100)
                }
            };*/
            //setIsProcessing(false);
            nextPage();
        };

        processVideos().catch(console.error);

        return () => {
            // URL.revokeObjectURL(url); should be run when finished in the final component
            Object.values(videoRefs.current).forEach(video => {
                video.pause();
                video.removeAttribute('src');
                video.load();
                video.remove();
            });
        };
    }, [videoData]);

    const handleClose = () => {
        clearExtractedImages();
        onClose();
    }

    return (
        <Box>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <Flex justify={'center'} align={'center'} direction={'column'}>
                <Spinner />
                <Flex w={['full']} direction={'column'} mt={'20px'}>
                    <Progress 
                        value={progress}
                        isAnimated={true}
                        hasStripe
                        colorScheme="brand"
                    />
                    <Text mt={'8px'}>
                        {processingText}
                    </Text>
                </Flex>
                <Flex w={['full']} justify={'flex-end'} mt={'20px'}>
                    <Button 
                        onClick={handleClose}
                        variant={'outline'}
                    >
                        <FormattedMessage 
                            id={'pdfreport.extractFrames.cancel.button'} 
                            defaultMessage={'Cancel'}
                        />
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
};