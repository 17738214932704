import React from 'react';
import { Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppConfig } from 'src/App/state/appConfig';
import { useUnsendReport } from 'src/Reports/api/unsendReport';

export const UnsendConfirmationDialog = ({ isOpen, onClose, reportUid, queryParams={},  onSuccess=null, useQuerySuccess=null, useQueryError=null}) => {
    const clinic = useAppConfig(state => state.clinic?.id) 
    const [ error, setError ] = React.useState(null);
    const [ reason, setReason ] = React.useState("");
    const { formatMessage } = useIntl();
    const unsendMutation = useUnsendReport({ id: reportUid, clinic, queryParams, config: {onSuccess: useQuerySuccess, onError: useQueryError}})
  
    const handleClose = () => {
      setError(null);
      setReason("")
      onClose();
    }
  
    const handleUnsend = async () => {
        try{
            const resp = await unsendMutation.mutateAsync({"unsend_reason": reason});  
            onSuccess && onSuccess(resp);
            handleClose();
        } catch (err){
            console.error(err);
            setError(err);
        }
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'confirmationDialog.unsendRequest.title'}
                            defaultMessage='Update Patient Request'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        <FormattedMessage
                            id={'confirmationDialog.unsendRequest.description'}
                            defaultMessage='Are you sure you want to update the request to pending?'
                        />
                    </Text>
                    <Textarea 
                      mt={'15px'}
                      borderRadius={'15px'}
                      placeholder={formatMessage({
                          id: 'confirmationDialog.unsendRequest.placeholder',
                          defaultMessage: 'Please provide a reason (optional)'
                      })}
                      value={reason} 
                      onChange={(event) => setReason(event.target.value)}
                    />
                </ModalBody>
                <Flex w={'full'} justify={'center'} align={'center'}>
                    {error && <Text color="red">{error.message}</Text>}
                </Flex>
                <ModalFooter pb={'20px'}>
                    <Button 
                        isLoading={unsendMutation.isLoading}
                        variant={'delete'} 
                        mr={3} 
                        onClick={handleUnsend}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.unsendRequest.confirm'}
                            defaultMessage={'Yes, Update'}
                        />
                    </Button>
                    <Button 
                        isDisabled={unsendMutation.isLoading}
                        variant="outlined" 
                        onClick={handleClose}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.unsendRequest.cancel'}
                            defaultMessage={'No'} 
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
  }
  