import { Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppConfig } from 'src/App/state/appConfig';
import { useCancelDentistRequest } from 'src/RequestListPage/api/cancelDentistRequest';


export const CancelConfirmationDialog = ({ isOpen, onClose, requestId, onSuccess, useQuerySuccess=null, useQueryError=null  }) => {
    const clinic = useAppConfig(state => state.clinic?.id) 
    const [ error, setError ] = React.useState(null);
    const [ reason, setReason ] = React.useState("");
    const { formatMessage } = useIntl(); 
    const cancelMutation = useCancelDentistRequest({id: requestId, clinic, config: {onSuccess: useQuerySuccess, onError: useQueryError}})
  
    const handleClose = () => {
      setError(null);
      setReason("");
      onClose();
    }
  
    const handleCancel = async () => {
        try{
            const resp = await cancelMutation.mutateAsync({"cancel_reason": reason});  
            onSuccess && onSuccess(resp);
            handleClose();
        } catch (err){
            console.error(err);
            setError(err);
        }
    }
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'confirmationDialog.unsendRequest.title'}
                            defaultMessage='Cancel Patient Request?'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        <FormattedMessage
                            id={'confirmationDialog.cancelRequest.description'}
                            defaultMessage='Are you sure you want to cancel the request?'
                        />
                    </Text>
                    <Textarea 
                      mt={'15px'}
                      borderRadius={'15px'}
                      placeholder={formatMessage({
                          id: 'confirmationDialog.cancelRequest.placeholder',
                          defaultMessage: 'Please provide a reason (optional)'
                      })}
                      value={reason} 
                      onChange={(event) => setReason(event.target.value)}
                    />
                </ModalBody>
                <Flex w={'full'} justify={'center'} align={'center'}>
                    {error && <Text color="red">{error.message}</Text>}
                </Flex>
                <ModalFooter pb={'20px'}>
                    <Button 
                        isLoading={cancelMutation.isLoading}
                        variant={'delete'} 
                        mr={3} 
                        onClick={handleCancel}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.cancelRequest.confirm'}
                            defaultMessage={'Yes, Cancel'}
                        />
                    </Button>
                    <Button 
                        isDisabled={cancelMutation.isLoading}
                        variant="outlined" 
                        onClick={handleClose}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.cancelRequest.cancel'}
                            defaultMessage={'No'} 
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
  }