import React from 'react';
import styled from 'styled-components';
import { TOOTH_NUMBERING_SYSTEM } from '../../_config';
import { ToothDefs } from './ToothDefs';

const StyledToothSet = styled.div`
background: transparent;

#toothnumbers .toothnumber{
    font-family: 'DM Sans';
    fill:#273238;
    white-space: pre;
    
    letter-spacing: -0.3px;

    > tspan{
        font-size: 6pt;
        font-family: 'DM Sans';
        letter-spacing: -0.3px;
    }

    &.ALL {
        fill:#fff;}
    &.GENERAL {
        fill:#fff;}
    &.OTHERS {
        fill:#fff;}
    &.OTHER {
        fill:#fff;}
    &.TOOTH {
        fill:#fff;}
    &.CARIES {
        fill:#fff;}
    &.ORTHO{
        fill:#fff;}
    &.GUMS{
        fill:#fff;}
    &.HABITS{
        fill:#fff;}
    &.CALCULUS{
        fill:#fff;}
    &.COLOR { 
        fill:#fff;}
}

#toothregions .toothregion{
    fill:#F2F5F7;
    &.ALL {
        fill:#000000;}
    &.GENERAL {
        fill:#A9A8A8;}
    &.OTHERS {
        fill:#367FFF;}
    &.OTHER {
        fill:#367FFF;}
    &.TOOTH {
        fill:#DC4E41;}
    &.CARIES {
        fill:#DC4E41;}
    &.ORTHO{
        fill:#00574F;}
    &.GUMS{
        fill:#FFA500;}
    &.HABITS{
        fill:#DAFF00;}
    &.CALCULUS{
        fill:#FFA500;}
    &.COLOR { 
        fill:#367FFF;}
}

#tongue{
    fill:#F2F5F7;
}
`;

const defaultToothNumberStyles = {
    fontFamily: 'DM Sans',
    fill: '#273238',
    whiteSpace: 'pre',
    letterSpacing: '-0.3px',

}

const toothNumberStyles = {
    'ALL': {fill: '#fff'},
    'GENERAL': {fill: '#fff'},
    'OTHER': {fill: '#fff'},
    'OTHERS': {fill: '#fff'},
    'CARIES': {fill: '#fff'},
    'TOOTH': {fill: '#fff'},
    'ORTHO': {fill: '#fff'},
    'GUMS': {fill: '#fff'},
    'HABITS': {fill: '#fff'},
    'CALCULUS': {fill: '#fff'},
    'COLOR': {fill: '#fff'},
    'MULTIPLE': {fill: '#fff'},
}

const defaultToothRegionStyles = {
    fill: "#F2F5F7",
}

const toothRegionStyles= {
    'ALL': {fill: '#000000'},
    'GENERAL': {fill: '#A9A8A8'},
    'OTHER': {fill: '#367FFF'},
    'OTHERS': {fill: '#367FFF'},
    'CARIES': {fill: '#DC4E41'},
    'TOOTH': {fill: '#DC4E41'},
    'ORTHO': {fill: '#00574F'},
    'GUMS': {fill: '#FFA500'},
    'HABITS': {fill: '#DAFF00'},
    'CALCULUS': {fill: '#FFA500'},
    'COLOR': {fill: '#367FFF'},
    'MULTIPLE': {fill: '#273238'},
}

const tspanStyles = {
    fontSize: '6pt',
    fontFamily: 'DM Sans',
    letterSpacing: '-0.3px',
}

const tongueStyles = {
    fill: '#F2F5F7',
}

const getMultipleClassRegionStyle = (toothClasses=[]) => {
    // Remove duplicates, sort the array, and join with underscores
    const uniqueSortedItems = [...new Set(toothClasses)].sort();
    // Join the sorted items with underscores
    if (uniqueSortedItems.length == 1){
        return toothRegionStyles[uniqueSortedItems[0]]
    }
    return {fill: `url(#${uniqueSortedItems.join('_')})`}
}

const getToothRegionStylesFromClasses = (toothCategories=[]) => {
    if (toothCategories.length == 0){
        return defaultToothRegionStyles
    } else if (toothCategories.length == 1){
        return {...defaultToothRegionStyles, ...toothRegionStyles[toothCategories[0]]}
    } else { // length more than 2
        return {...defaultToothRegionStyles, ...getMultipleClassRegionStyle(toothCategories)}
    }
}

const getToothNumberStylesFromClasses = (toothCategories=[]) => {
    if (toothCategories.length == 0){
        return defaultToothNumberStyles
    } else if (toothCategories.length == 1){
        return {...defaultToothNumberStyles, ...toothNumberStyles[toothCategories[0]]}
    } else {
        return {...defaultToothNumberStyles, ...toothNumberStyles['MULTIPLE']}
    }
}

const ToothSetTop = ({toothClasses={}, width=125, height=105, cssId='', numberingSystem="palmer"}) => (
    <StyledToothSet id={cssId} >
        <svg width={`${width}pt`} height={`${height}pt`} viewBox="0 0 126 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ToothDefs />
            <g id="upper">
                <g id="toothregions">
                    <path id="t13" style={getToothRegionStylesFromClasses(toothClasses["t13"])} fillRule="evenodd" clipRule="evenodd" d="M39.4847 21.3396C39.0556 23.1398 38.2011 24.7477 36.8655 26.056C35.7208 27.1764 34.3477 27.4107 32.9321 26.6651C31.422 25.8693 30.0546 24.9137 28.8554 23.7771C27.7738 22.7519 26.8274 21.5802 26.0523 20.2286C25.8747 19.9191 25.7227 19.592 25.5894 19.2612C24.4028 16.2999 25.5363 14.5704 28.7434 14.4476C29.1 14.4338 29.4578 14.4457 29.815 14.4457C31.5878 14.5892 33.3687 14.7095 35.0627 15.2083C35.7802 15.4189 36.4833 15.6908 37.1608 16.0718C39.2101 17.2247 40.0327 19.0393 39.4847 21.3396Z" />
                    <path id="t18" style={getToothRegionStylesFromClasses(toothClasses["t18"])} fillRule="evenodd" clipRule="evenodd" d="M15.3164 104.288C13.5148 104.814 11.6751 105.207 9.90856 105.13C9.72027 105.122 9.53136 105.113 9.34432 105.094C7.23748 105.201 5.34646 104.98 3.53801 104.257C1.41303 103.407 0.378375 101.879 0.340842 99.6085C0.325204 98.6585 0.344596 97.7111 0.351477 96.7625C0.359609 95.6145 0.348349 94.4647 0.224491 93.3036C-0.130819 89.9739 2.03795 87.8967 5.8788 87.7444C6.90157 87.7037 7.92809 87.7012 8.95461 87.7156C10.556 87.7394 12.158 87.8159 13.7507 87.9418C16.6613 88.1712 18.4961 90.5698 18.0751 93.5712C17.9687 94.33 18.0106 95.0224 18.1608 95.6947C18.2696 96.1822 18.4335 96.6597 18.6468 97.1434C20.3064 100.9 19.288 103.129 15.3164 104.288Z" />
                    <path id="t14" style={getToothRegionStylesFromClasses(toothClasses["t14"])} fillRule="evenodd" clipRule="evenodd" d="M31.3064 34.4592C31.135 34.8263 30.9486 35.1892 30.7309 35.5426C29.2615 37.9243 26.6148 39.0866 23.8618 37.7325C23.2513 37.4324 22.647 37.1291 22.0577 36.8051C20.898 36.1685 19.7964 35.4467 18.8286 34.4723C17.181 32.8125 17.1835 29.3781 18.671 27.1518C19.224 26.324 19.9753 25.6586 20.933 25.3196C22.2297 24.8615 23.5409 24.5207 24.7751 24.6015C26.0287 24.5758 27.1797 24.7444 28.2881 25.0163C28.8311 25.1498 29.3647 25.3058 29.8927 25.4794C31.4753 26.0001 32.615 27.5954 32.51 29.3242C32.4011 31.1062 32.0608 32.845 31.3064 34.4592Z" />
                    <path id="t15" style={getToothRegionStylesFromClasses(toothClasses["t15"])} fillRule="evenodd" clipRule="evenodd" d="M27.2484 43.2175C27.1264 44.5897 26.9143 45.9445 26.4977 47.2171C25.9904 48.7667 25.1784 50.1947 23.8435 51.3802C22.568 52.5131 21.0204 53.0952 19.3371 52.6202C18.2824 52.3226 17.2778 51.9285 16.3195 51.4466C14.897 50.731 13.579 49.8168 12.3704 48.6933C10.3768 46.8399 10.3092 43.5916 11.4853 41.1773C12.1277 39.8583 13.1361 38.7849 14.4172 38.3319C15.8459 37.8269 17.2891 37.5486 18.8792 37.481C19.8601 37.5781 20.8778 37.6539 21.8937 37.8187C22.5268 37.9215 23.1586 38.0587 23.7803 38.258C26.2619 39.0537 27.4786 40.6309 27.2484 43.2175Z" />
                    <path id="t16" style={getToothRegionStylesFromClasses(toothClasses["t16"])} fillRule="evenodd" clipRule="evenodd" d="M22.2959 58.1796C21.8568 59.3263 21.4771 60.4836 21.1731 61.6641C20.9535 62.5176 20.7677 63.3823 20.6514 64.272C20.2235 67.5573 17.5643 69.4233 14.3352 68.6714C13.6115 68.5028 12.894 68.3004 12.1796 68.0849C10.9072 67.7008 9.64865 67.2603 8.4107 66.7859C6.4934 66.0522 5.35053 63.7306 5.74462 61.5626C5.94292 60.4692 6.2388 59.399 6.5816 58.3431C6.82118 57.6063 7.08079 56.875 7.35915 56.1532C7.95342 54.6155 9.25456 53.7965 10.769 53.3354C12.2659 52.8786 13.8004 52.6217 15.226 52.7495C15.5338 52.7439 15.8309 52.7526 16.1293 52.7608C17.4924 52.7952 18.7603 52.9676 19.9864 53.3604C22.4636 54.1531 23.2255 55.7515 22.2959 58.1796Z" />
                    <path id="t17" style={getToothRegionStylesFromClasses(toothClasses["t17"])} fillRule="evenodd" clipRule="evenodd" d="M19.5125 75.3286C19.1572 76.3299 18.9846 77.3055 18.9452 78.2867C18.9076 79.1984 18.9852 80.1151 19.146 81.0626C19.5851 83.6479 18.2496 85.7508 15.7161 86.4256C13.5304 87.0077 11.3592 87.192 9.21856 86.9864C7.29688 86.8022 5.3996 86.3059 3.54111 85.4644C1.38485 84.4876 0.517221 82.6685 0.824989 80.2712C0.929455 79.4591 1.11274 78.6733 1.36233 77.897C1.54374 77.3318 1.69888 76.7647 1.82461 76.1908C1.97412 75.5071 2.08108 74.8122 2.11737 74.0916C2.30628 70.3797 4.64207 68.2812 9.07719 68.3438C9.88602 68.4955 10.8137 68.6484 11.8033 68.8194C13.3071 69.0788 14.9598 69.3815 16.5875 69.7925C19.0146 70.406 20.3614 72.9381 19.5125 75.3286Z" />
                    <path id="t11" style={getToothRegionStylesFromClasses(toothClasses["t11"])} fillRule="evenodd" clipRule="evenodd" d="M62.0055 4.04906C61.9348 4.49645 61.8647 4.96202 61.7853 5.4376C61.4143 7.64135 60.8226 10.0675 58.9015 12.0169C57.9388 12.9944 56.8053 13.2381 55.5105 12.9837C53.266 12.5432 51.4913 11.2217 49.7229 9.88834C48.9241 9.28555 48.2172 8.59316 47.5554 7.85503C47.2445 7.50789 46.9399 7.15323 46.6471 6.78793C45.2246 5.01402 45.3516 3.9864 47.1469 2.64986C48.0308 1.99131 49.0523 1.63916 50.0888 1.41045C52.1619 0.952407 54.2631 0.308263 56.3481 0.325808C57.8863 0.33834 59.5959 0.446115 61.0709 1.24064C61.8604 1.6661 62.0336 2.0972 62.0055 4.04906Z" />
                    <path id="t12" style={getToothRegionStylesFromClasses(toothClasses["t12"])} fillRule="evenodd" clipRule="evenodd" d="M46.768 14.2666C46.3232 16.3275 45.3017 17.5118 42.5174 17.273C39.9602 17.0544 37.8652 15.9647 36.028 14.4414C35.1622 13.7234 34.3534 12.9107 33.5809 12.0447C32.8396 11.2144 32.9941 10.2551 33.9312 9.26322C35.395 7.71426 37.2528 6.78752 39.1989 6.02182C40.749 5.41151 42.3197 4.94469 44.0312 5.17466C44.6893 5.26301 45.1316 5.55437 45.3912 6.12207C45.7484 6.90219 46.0386 7.69922 46.2713 8.51756C46.6617 9.89483 46.8856 11.3348 46.9751 12.8637C46.9206 13.2396 46.8775 13.7603 46.768 14.2666Z" />
                    <path id="t23" style={getToothRegionStylesFromClasses(toothClasses["t23"])} fillRule="evenodd" clipRule="evenodd" d="M85.8834 21.3396C86.3125 23.1398 87.167 24.7477 88.5026 26.056C89.6473 27.1764 91.0204 27.4107 92.436 26.6651C93.9461 25.8693 95.3135 24.9137 96.5127 23.7771C97.5943 22.7519 98.5407 21.5802 99.3158 20.2286C99.4934 19.9191 99.6454 19.592 99.7787 19.2612C100.965 16.2999 99.8318 14.5704 96.6247 14.4476C96.2681 14.4338 95.9103 14.4457 95.5531 14.4457C93.7803 14.5892 91.9994 14.7095 90.3054 15.2083C89.5879 15.4189 88.8848 15.6908 88.2073 16.0718C86.158 17.2247 85.3354 19.0393 85.8834 21.3396Z" />
                    <path id="t28" style={getToothRegionStylesFromClasses(toothClasses["t28"])} fillRule="evenodd" clipRule="evenodd" d="M110.058 104.289C111.859 104.814 113.699 105.207 115.466 105.131C115.654 105.123 115.843 105.114 116.03 105.094C118.137 105.202 120.028 104.981 121.836 104.257C123.961 103.408 124.996 101.88 125.033 99.6092C125.049 98.6593 125.03 97.7119 125.023 96.7632C125.015 95.6153 125.026 94.4654 125.15 93.3044C125.505 89.9746 123.336 87.8974 119.495 87.7452C118.473 87.7044 117.446 87.7019 116.42 87.7163C114.818 87.7402 113.216 87.8166 111.624 87.9425C108.713 88.1719 106.878 90.5705 107.299 93.5719C107.405 94.3307 107.364 95.0231 107.213 95.6955C107.105 96.183 106.941 96.6604 106.727 97.1442C105.068 100.901 106.086 103.13 110.058 104.289Z" />
                    <path id="t24" style={getToothRegionStylesFromClasses(toothClasses["t24"])} fillRule="evenodd" clipRule="evenodd" d="M94.061 34.4592C94.2324 34.8263 94.4188 35.1892 94.6365 35.5426C96.1059 37.9243 98.7526 39.0866 101.506 37.7325C102.116 37.4324 102.72 37.1291 103.31 36.8051C104.469 36.1685 105.571 35.4467 106.539 34.4723C108.186 32.8125 108.184 29.3781 106.696 27.1518C106.143 26.324 105.392 25.6586 104.434 25.3196C103.138 24.8615 101.827 24.5207 100.592 24.6015C99.3387 24.5758 98.1877 24.7444 97.0793 25.0163C96.5363 25.1498 96.0027 25.3058 95.4747 25.4794C93.8921 26.0001 92.7524 27.5954 92.8575 29.3242C92.9663 31.1062 93.3066 32.845 94.061 34.4592Z" />
                    <path id="t25" style={getToothRegionStylesFromClasses(toothClasses["t25"])} fillRule="evenodd" clipRule="evenodd" d="M98.1224 43.2175C98.2444 44.5897 98.4565 45.9445 98.8731 47.2171C99.3804 48.7667 100.192 50.1947 101.527 51.3802C102.803 52.5131 104.35 53.0952 106.034 52.6202C107.088 52.3226 108.093 51.9285 109.051 51.4466C110.474 50.731 111.792 49.8168 113 48.6933C114.994 46.8399 115.062 43.5916 113.886 41.1773C113.243 39.8583 112.235 38.7849 110.954 38.3319C109.525 37.8269 108.082 37.5486 106.492 37.481C105.511 37.5781 104.493 37.6539 103.477 37.8187C102.844 37.9215 102.212 38.0587 101.59 38.258C99.1089 39.0537 97.8922 40.6309 98.1224 43.2175Z" />
                    <path id="t26" style={getToothRegionStylesFromClasses(toothClasses["t26"])} fillRule="evenodd" clipRule="evenodd" d="M103.075 58.1796C103.514 59.3263 103.894 60.4836 104.198 61.6641C104.418 62.5176 104.603 63.3823 104.72 64.272C105.148 67.5573 107.807 69.4233 111.036 68.6714C111.76 68.5028 112.477 68.3004 113.191 68.0849C114.464 67.7008 115.722 67.2603 116.96 66.7859C118.878 66.0522 120.021 63.7306 119.626 61.5626C119.428 60.4692 119.132 59.399 118.79 58.3431C118.55 57.6063 118.29 56.875 118.012 56.1532C117.418 54.6155 116.117 53.7965 114.602 53.3354C113.105 52.8786 111.571 52.6217 110.145 52.7495C109.837 52.7439 109.54 52.7526 109.242 52.7608C107.879 52.7952 106.611 52.9676 105.385 53.3604C102.908 54.1531 102.146 55.7515 103.075 58.1796Z" />
                    <path id="t27" style={getToothRegionStylesFromClasses(toothClasses["t27"])} fillRule="evenodd" clipRule="evenodd" d="M105.859 75.3261C106.215 76.3274 106.387 77.303 106.427 78.2843C106.464 79.196 106.387 80.1127 106.226 81.0601C105.787 83.6455 107.122 85.7483 109.656 86.4232C111.842 87.0053 114.013 87.1895 116.153 86.984C118.075 86.7998 119.972 86.3035 121.831 85.462C123.987 84.4851 124.855 82.6661 124.547 80.2687C124.443 79.4567 124.259 78.6709 124.01 77.8945C123.828 77.3293 123.673 76.7623 123.547 76.1883C123.398 75.5047 123.291 74.8098 123.255 74.0892C123.066 70.3772 120.73 68.2787 116.295 68.3414C115.486 68.493 114.558 68.6459 113.569 68.817C112.065 69.0764 110.412 69.3791 108.785 69.7901C106.357 70.4035 105.011 72.9356 105.859 75.3261Z" />
                    <path id="t21" style={getToothRegionStylesFromClasses(toothClasses["t21"])} fillRule="evenodd" clipRule="evenodd" d="M63.3711 4.04906C63.4418 4.49645 63.5119 4.96202 63.5913 5.4376C63.9623 7.64135 64.554 10.0675 66.4751 12.0169C67.4378 12.9944 68.5713 13.2381 69.8662 12.9837C72.1106 12.5432 73.8853 11.2217 75.6537 9.88834C76.4525 9.28555 77.1594 8.59316 77.8212 7.85503C78.1321 7.50789 78.4368 7.15323 78.7295 6.78793C80.152 5.01402 80.025 3.9864 78.2297 2.64986C77.3458 1.99131 76.3243 1.63916 75.2878 1.41045C73.2147 0.952407 71.1135 0.308263 69.0286 0.325808C67.4904 0.33834 65.7807 0.446115 64.3057 1.24064C63.5163 1.6661 63.343 2.0972 63.3711 4.04906Z" />
                    <path id="t22" style={getToothRegionStylesFromClasses(toothClasses["t22"])} fillRule="evenodd" clipRule="evenodd" d="M78.5969 14.2666C79.0416 16.3275 80.0631 17.5118 82.8474 17.273C85.4047 17.0544 87.4996 15.9647 89.3368 14.4414C90.2026 13.7234 91.0114 12.9107 91.784 12.0447C92.5253 11.2144 92.3707 10.2551 91.4337 9.26322C89.9699 7.71426 88.112 6.78752 86.166 6.02182C84.6159 5.41151 83.0451 4.94469 81.3336 5.17466C80.6755 5.26301 80.2333 5.55437 79.9737 6.12207C79.6165 6.90219 79.3262 7.69922 79.0935 8.51756C78.7032 9.89483 78.4793 11.3348 78.3898 12.8637C78.4442 13.2396 78.4874 13.7603 78.5969 14.2666Z" />
                </g>
                <g id="toothnumbers">
                    <text id="toothnumber_t18" style={getToothNumberStylesFromClasses(toothClasses["t18"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="4.61402" y="98.8608">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t18"]}</tspan></text>
                    <text id="toothnumber_t28" style={getToothNumberStylesFromClasses(toothClasses["t28"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="110.768" y="98.8608">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t28"]}</tspan></text>
                    <text id="toothnumber_t17" style={getToothNumberStylesFromClasses(toothClasses["t17"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="7.36793" y="81.0126">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t17"]}</tspan></text>
                    <text id="toothnumber_t27" style={getToothNumberStylesFromClasses(toothClasses["t27"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="110.51" y="81.0126">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t27"]}</tspan></text>
                    <text id="toothnumber_t16" style={getToothNumberStylesFromClasses(toothClasses["t16"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="9.53785" y="64.1562">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t16"]}</tspan></text>
                    <text id="toothnumber_t26" style={getToothNumberStylesFromClasses(toothClasses["t26"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="105.772" y="63.1645">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t26"]}</tspan></text>
                    <text id="toothnumber_t15" style={getToothNumberStylesFromClasses(toothClasses["t15"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="14.5027" y="47.2995">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t15"]}</tspan></text>
                    <text id="toothnumber_t25" style={getToothNumberStylesFromClasses(toothClasses["t25"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="100.196" y="47.654">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t25"]}</tspan></text>
                    <text id="toothnumber_t14" style={getToothNumberStylesFromClasses(toothClasses["t14"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="22.0769" y="34.4094">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t14"]}</tspan></text>
                    <text id="toothnumber_t24" style={getToothNumberStylesFromClasses(toothClasses["t24"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="95.9578" y="33.6432">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t24"]}</tspan></text>
                    <text id="toothnumber_t13" style={getToothNumberStylesFromClasses(toothClasses["t13"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="29.2097" y="23.5695">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t13"]}</tspan></text>
                    <text id="toothnumber_t12" style={getToothNumberStylesFromClasses(toothClasses["t12"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="36.3972" y="14.457">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t12"]}</tspan></text>
                    <text id="toothnumber_t11" style={getToothNumberStylesFromClasses(toothClasses["t11"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="50.7175" y="9.90008">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t11"]}</tspan></text>
                    <text id="toothnumber_t21" style={getToothNumberStylesFromClasses(toothClasses["t21"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="65.6941" y="9.90008">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t21"]}</tspan></text>
                    <text id="toothnumber_t22" style={getToothNumberStylesFromClasses(toothClasses["t22"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="78.7195" y="14.457">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t22"]}</tspan></text>
                    <text id="toothnumber_t23" style={getToothNumberStylesFromClasses(toothClasses["t23"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="87.8074" y="23.5695">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t23"]}</tspan></text>
                </g>
            </g>
        </svg>
    </StyledToothSet>
)

const ToothSetBottom = ({toothClasses={}, width=125, height=105, cssId='', numberingSystem="palmer"}) => (
    <StyledToothSet id={cssId} >
        <svg width={`${width}pt`} height={`${height}pt`} viewBox="0 0 126 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ToothDefs />
            <g id="Group 1261160704">
                <g id="toothregions">
                    <path id="t43" style={getToothRegionStylesFromClasses(toothClasses["t43"])} fillRule="evenodd" clipRule="evenodd" d="M39.798 84.4504C39.3689 82.6502 38.5144 81.0423 37.1789 79.734C36.0341 78.6136 34.661 78.3793 33.2454 79.1249C31.7354 79.9207 30.3679 80.8763 29.1687 82.0129C28.0872 83.038 27.1407 84.2098 26.3657 85.5614C26.188 85.8709 26.036 86.198 25.9028 86.5288C24.7161 89.4901 25.8496 91.2195 29.0568 91.3424C29.4133 91.3561 29.7711 91.3442 30.1283 91.3442C31.9011 91.2007 33.682 91.0804 35.376 90.5817C36.0935 90.3711 36.7966 90.0992 37.4741 89.7182C39.5234 88.5653 40.346 86.7506 39.798 84.4504Z" />
                    <path id="t48" style={getToothRegionStylesFromClasses(toothClasses["t48"])} fillRule="evenodd" clipRule="evenodd" d="M15.6297 1.50256C13.8282 0.97684 11.9884 0.583963 10.2219 0.660408C10.0336 0.668554 9.84468 0.677326 9.65765 0.696751C7.55081 0.588976 5.65978 0.810165 3.85133 1.53389C1.72635 2.38356 0.691699 3.9112 0.654166 6.182C0.638528 7.13192 0.65792 8.07934 0.664801 9.02801C0.672933 10.1759 0.661673 11.3258 0.537815 12.4868C0.182505 15.8166 2.35127 17.8938 6.19212 18.046C7.21489 18.0868 8.24141 18.0893 9.26793 18.0749C10.8693 18.051 12.4713 17.9746 14.064 17.8487C16.9747 17.6193 18.8094 15.2207 18.3884 12.2193C18.282 11.4605 18.324 10.7681 18.4741 10.0957C18.5829 9.60824 18.7468 9.13078 18.9601 8.64704C20.6197 4.89058 19.6013 2.66114 15.6297 1.50256Z" />
                    <path id="t44" style={getToothRegionStylesFromClasses(toothClasses["t44"])} fillRule="evenodd" clipRule="evenodd" d="M31.6197 71.3328C31.4483 70.9656 31.2619 70.6028 31.0442 70.2494C29.5748 67.8677 26.9281 66.7053 24.1751 68.0594C23.5646 68.3596 22.9603 68.6628 22.371 68.9868C21.2113 69.6234 20.1097 70.3452 19.142 71.3196C17.4943 72.9795 17.4968 76.4139 18.9843 78.6402C19.5373 79.4679 20.2886 80.1334 21.2463 80.4723C22.5431 80.9304 23.8542 81.2713 25.0884 81.1904C26.342 81.2161 27.493 81.0476 28.6015 80.7756C29.1444 80.6422 29.678 80.4861 30.206 80.3126C31.7886 79.7919 32.9284 78.1965 32.8233 76.4678C32.7144 74.6857 32.3741 72.9469 31.6197 71.3328Z" />
                    <path id="t45" style={getToothRegionStylesFromClasses(toothClasses["t45"])} fillRule="evenodd" clipRule="evenodd" d="M27.5617 62.5725C27.4397 61.2002 27.2277 59.8455 26.811 58.5729C26.3037 57.0233 25.4918 55.5953 24.1569 54.4098C22.8814 53.2769 21.3338 52.6948 19.6504 53.1697C18.5958 53.4674 17.5911 53.8615 16.6328 54.3434C15.2103 55.0589 13.8923 55.9731 12.6837 57.0966C10.6901 58.9501 10.6225 62.1984 11.7986 64.6127C12.441 65.9317 13.4494 67.0051 14.7305 67.4581C16.1593 67.9631 17.6024 68.2413 19.1925 68.309C20.1734 68.2119 21.1911 68.1361 22.207 67.9713C22.8401 67.8685 23.4719 67.7313 24.0937 67.532C26.5752 66.7362 27.7919 65.1591 27.5617 62.5725Z" />
                    <path id="t47" style={getToothRegionStylesFromClasses(toothClasses["t47"])} fillRule="evenodd" clipRule="evenodd" d="M19.8259 30.4639C19.4706 29.4626 19.2979 28.4869 19.2585 27.5057C19.221 26.594 19.2985 25.6773 19.4593 24.7299C19.8984 22.1445 18.5629 20.0416 16.0294 19.3668C13.8438 18.7847 11.6725 18.6005 9.53189 18.806C7.61021 18.9902 5.71293 19.4865 3.85443 20.328C1.69818 21.3049 0.830545 23.1239 1.13831 25.5213C1.24278 26.3333 1.42606 27.1191 1.67566 27.8954C1.85707 28.4606 2.0122 29.0277 2.13794 29.6017C2.28744 30.2853 2.39441 30.9802 2.43069 31.7008C2.61961 35.4128 4.95539 37.5112 9.39051 37.4486C10.1993 37.2969 11.127 37.144 12.1166 36.973C13.6205 36.7136 15.2731 36.4109 16.9008 35.9999C19.3279 35.3864 20.6747 32.8543 19.8259 30.4639Z" />
                    <path id="t46" style={getToothRegionStylesFromClasses(toothClasses["t46"])} fillRule="evenodd" clipRule="evenodd" d="M22.6034 47.6116C22.1643 46.4649 21.7846 45.3076 21.4805 44.1271C21.261 43.2736 21.0752 42.4089 20.9588 41.5192C20.531 38.2339 17.8718 36.3679 14.6427 37.1198C13.9189 37.2884 13.2014 37.4908 12.4871 37.7063C11.2147 38.0904 9.95611 38.5309 8.71816 39.0052C6.80086 39.739 5.65799 42.0605 6.05209 44.2286C6.25038 45.322 6.54627 46.3922 6.88907 47.448C7.12865 48.1849 7.38825 48.9162 7.66662 49.638C8.26089 51.1757 9.56202 51.9946 11.0765 52.4558C12.5734 52.9126 14.1079 53.1695 15.5335 53.0417C15.8412 53.0473 16.1384 53.0386 16.4368 53.0304C17.7998 52.996 19.0678 52.8236 20.2939 52.4308C22.771 51.6381 23.533 50.0397 22.6034 47.6116Z" />
                    <path id="t41" style={getToothRegionStylesFromClasses(toothClasses["t41"])} fillRule="evenodd" clipRule="evenodd" d="M62.3188 101.742C62.2481 101.295 62.1781 100.829 62.0986 100.354C61.7277 98.1498 61.1359 95.7236 59.2149 93.7743C58.2521 92.7968 57.1187 92.5531 55.8238 92.8075C53.5793 93.248 51.8046 94.5695 50.0362 95.9029C49.2374 96.5056 48.5305 97.198 47.8687 97.9362C47.5578 98.2833 47.2532 98.638 46.9604 99.0033C45.5379 100.777 45.6649 101.805 47.4602 103.141C48.3441 103.8 49.3656 104.152 50.4022 104.381C52.4752 104.839 54.5764 105.483 56.6614 105.465C58.1996 105.453 59.9092 105.345 61.3842 104.551C62.1737 104.125 62.347 103.694 62.3188 101.742Z" />
                    <path id="t42" style={getToothRegionStylesFromClasses(toothClasses["t42"])} fillRule="evenodd" clipRule="evenodd" d="M47.0813 91.5234C46.6366 89.4625 45.615 88.2782 42.8307 88.5169C40.2735 88.7356 38.1786 89.8253 36.3413 91.3485C35.4756 92.0666 34.6667 92.8793 33.8942 93.7453C33.1529 94.5755 33.3074 95.5349 34.2445 96.5268C35.7083 98.0757 37.5661 99.0025 39.5122 99.7682C41.0623 100.378 42.6331 100.845 44.3446 100.615C45.0026 100.527 45.4449 100.236 45.7045 99.6679C46.0617 98.8878 46.3519 98.0908 46.5846 97.2724C46.975 95.8952 47.1989 94.4552 47.2884 92.9263C47.234 92.5504 47.1908 92.0297 47.0813 91.5234Z" />
                    <path id="t33" style={getToothRegionStylesFromClasses(toothClasses["t33"])} fillRule="evenodd" clipRule="evenodd" d="M86.1967 84.4504C86.6259 82.6502 87.4804 81.0423 88.8159 79.734C89.9606 78.6136 91.3337 78.3793 92.7493 79.1249C94.2594 79.9207 95.6268 80.8763 96.826 82.0129C97.9076 83.038 98.854 84.2098 99.6291 85.5614C99.8067 85.8709 99.9587 86.198 100.092 86.5288C101.279 89.4901 100.145 91.2195 96.938 91.3424C96.5814 91.3561 96.2236 91.3442 95.8664 91.3442C94.0936 91.2007 92.3127 91.0804 90.6187 90.5817C89.9012 90.3711 89.1981 90.0992 88.5206 89.7182C86.4714 88.5653 85.6488 86.7506 86.1967 84.4504Z" />
                    <path id="t38" style={getToothRegionStylesFromClasses(toothClasses["t38"])} fillRule="evenodd" clipRule="evenodd" d="M110.371 1.50256C112.173 0.97684 114.012 0.583963 115.779 0.660408C115.967 0.668554 116.156 0.677326 116.343 0.696751C118.45 0.588976 120.341 0.810165 122.149 1.53389C124.274 2.38356 125.309 3.9112 125.347 6.182C125.362 7.13192 125.343 8.07934 125.336 9.02801C125.328 10.1759 125.339 11.3258 125.463 12.4868C125.818 15.8166 123.65 17.8938 119.809 18.046C118.786 18.0868 117.759 18.0893 116.733 18.0749C115.131 18.051 113.529 17.9746 111.937 17.8487C109.026 17.6193 107.191 15.2207 107.612 12.2193C107.719 11.4605 107.677 10.7681 107.527 10.0957C107.418 9.60824 107.254 9.13078 107.041 8.64704C105.381 4.89058 106.4 2.66114 110.371 1.50256Z" />
                    <path id="t34" style={getToothRegionStylesFromClasses(toothClasses["t34"])} fillRule="evenodd" clipRule="evenodd" d="M94.3743 71.3328C94.5457 70.9656 94.7321 70.6028 94.9498 70.2494C96.4192 67.8677 99.0659 66.7053 101.819 68.0594C102.429 68.3596 103.034 68.6628 103.623 68.9868C104.783 69.6234 105.884 70.3452 106.852 71.3196C108.5 72.9795 108.497 76.4139 107.01 78.6402C106.457 79.4679 105.705 80.1334 104.748 80.4723C103.451 80.9304 102.14 81.2713 100.906 81.1904C99.6521 81.2161 98.5011 81.0476 97.3926 80.7756C96.8496 80.6422 96.316 80.4861 95.7881 80.3126C94.2054 79.7919 93.0657 78.1965 93.1708 76.4678C93.2796 74.6857 93.6199 72.9469 94.3743 71.3328Z" />
                    <path id="t35" style={getToothRegionStylesFromClasses(toothClasses["t35"])} fillRule="evenodd" clipRule="evenodd" d="M98.4358 62.5725C98.5577 61.2002 98.7698 59.8455 99.1864 58.5729C99.6937 57.0233 100.506 55.5953 101.841 54.4098C103.116 53.2769 104.664 52.6948 106.347 53.1697C107.402 53.4674 108.406 53.8615 109.365 54.3434C110.787 55.0589 112.105 55.9731 113.314 57.0966C115.307 58.9501 115.375 62.1984 114.199 64.6127C113.556 65.9317 112.548 67.0051 111.267 67.4581C109.838 67.9631 108.395 68.2413 106.805 68.309C105.824 68.2119 104.806 68.1361 103.79 67.9713C103.157 67.8685 102.526 67.7313 101.904 67.532C99.4222 66.7362 98.2056 65.1591 98.4358 62.5725Z" />
                    <path id="t36" style={getToothRegionStylesFromClasses(toothClasses["t36"])} fillRule="evenodd" clipRule="evenodd" d="M103.389 47.6109C103.828 46.4642 104.207 45.3068 104.511 44.1263C104.731 43.2729 104.917 42.4082 105.033 41.5184C105.461 38.2332 108.12 36.3672 111.349 37.1191C112.073 37.2876 112.79 37.49 113.505 37.7056C114.777 38.0897 116.036 38.5302 117.274 39.0045C119.191 39.7383 120.334 42.0598 119.94 44.2278C119.742 45.3213 119.446 46.3915 119.103 47.4473C118.863 48.1842 118.604 48.9154 118.325 49.6373C117.731 51.1749 116.43 51.9939 114.915 52.4551C113.418 52.9119 111.884 53.1688 110.458 53.041C110.151 53.0466 109.854 53.0378 109.555 53.0297C108.192 52.9952 106.924 52.8229 105.698 52.43C103.221 51.6374 102.459 50.0389 103.389 47.6109Z" />
                    <path id="t37" style={getToothRegionStylesFromClasses(toothClasses["t37"])} fillRule="evenodd" clipRule="evenodd" d="M106.173 30.4639C106.528 29.4626 106.701 28.4869 106.74 27.5057C106.778 26.594 106.7 25.6773 106.539 24.7299C106.1 22.1445 107.436 20.0416 109.969 19.3668C112.155 18.7847 114.326 18.6005 116.467 18.806C118.388 18.9902 120.286 19.4865 122.144 20.328C124.301 21.3049 125.168 23.1239 124.86 25.5213C124.756 26.3333 124.573 27.1191 124.323 27.8954C124.142 28.4606 123.986 29.0277 123.861 29.6017C123.711 30.2853 123.604 30.9802 123.568 31.7008C123.379 35.4128 121.043 37.5112 116.608 37.4486C115.799 37.2969 114.872 37.144 113.882 36.973C112.378 36.7136 110.726 36.4109 109.098 35.9999C106.671 35.3864 105.324 32.8543 106.173 30.4639Z" />
                    <path id="t31" style={getToothRegionStylesFromClasses(toothClasses["t31"])} fillRule="evenodd" clipRule="evenodd" d="M63.6845 101.742C63.7551 101.295 63.8252 100.829 63.9047 100.354C64.2756 98.1498 64.8674 95.7236 66.7884 93.7743C67.7511 92.7968 68.8846 92.5531 70.1795 92.8075C72.424 93.248 74.1986 94.5695 75.967 95.9029C76.7659 96.5056 77.4727 97.198 78.1346 97.9362C78.4455 98.2833 78.7501 98.638 79.0429 99.0033C80.4653 100.777 80.3384 101.805 78.543 103.141C77.6592 103.8 76.6376 104.152 75.6011 104.381C73.528 104.839 71.4268 105.483 69.3419 105.465C67.8037 105.453 66.0941 105.345 64.619 104.551C63.8296 104.125 63.6563 103.694 63.6845 101.742Z" />
                    <path id="t32" style={getToothRegionStylesFromClasses(toothClasses["t32"])} fillRule="evenodd" clipRule="evenodd" d="M78.9102 91.5234C79.3549 89.4625 80.3765 88.2782 83.1608 88.5169C85.718 88.7356 87.8129 89.8253 89.6502 91.3485C90.5159 92.0666 91.3248 92.8793 92.0973 93.7453C92.8386 94.5755 92.6841 95.5349 91.747 96.5268C90.2832 98.0757 88.4254 99.0025 86.4793 99.7682C84.9292 100.378 83.3584 100.845 81.6469 100.615C80.9889 100.527 80.5466 100.236 80.287 99.6679C79.9298 98.8878 79.6396 98.0908 79.4069 97.2724C79.0165 95.8952 78.7926 94.4552 78.7031 92.9263C78.7575 92.5504 78.8007 92.0297 78.9102 91.5234Z" />
                    <path id="tongue" style={tongueStyles} fillRule="evenodd" clipRule="evenodd" d="M83.4329 1.0673C89.5103 2.95961 92.9852 9.12675 92.3874 16.7819V16.8678C92.3953 17.3153 93.0501 63.2648 75.2384 73.3973L75.1333 73.4664C75.1333 73.4664 75.1289 73.4696 75.1231 73.4739C74.912 73.6315 71.5255 76.1583 64.264 76.1583C57.4435 76.1583 52.9469 73.8496 52.1778 73.4548C52.1545 73.4428 52.1347 73.4326 52.1183 73.4243C52.0639 73.3962 52.0137 73.3704 51.9604 73.3401C34.6958 63.3552 34.8907 19.0796 34.9113 16.8678C34.9118 16.8077 34.9097 16.7471 34.9049 16.6876C34.343 9.07621 37.8142 2.95119 43.8662 1.04147C46.0034 0.359231 48.2214 0.00884527 50.4527 0.000422524C54.6627 -0.0276533 58.7686 1.34413 62.2018 3.91363C62.6232 4.2292 63.1191 4.41282 63.6351 4.41282C64.1431 4.41282 64.6374 4.24829 65.0493 3.9316C67.3138 2.1909 73.9736 -1.9048 83.4329 1.0673ZM63.6971 47.7113C63.8681 47.7113 64.0069 47.5696 64.0069 47.3949V11.372C64.0069 11.1973 63.8681 11.0556 63.6971 11.0556C63.5261 11.0556 63.3873 11.1973 63.3873 11.372V47.3949C63.3873 47.5696 63.5261 47.7113 63.6971 47.7113Z" />
                </g>
                <g id="toothnumbers">
                    <text id="toothnumber_t48" style={getToothNumberStylesFromClasses(toothClasses["t48"])} xmlSpace="preserve"><tspan style={tspanStyles} x="4.8082" y="12.1784">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t48"]}</tspan></text>
                    <text id="toothnumber_t47" style={getToothNumberStylesFromClasses(toothClasses["t47"])} xmlSpace="preserve"><tspan style={tspanStyles} x="6.66406" y="30.478">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t47"]}</tspan></text>
                    <text id="toothnumber_t37" style={getToothNumberStylesFromClasses(toothClasses["t37"])} xmlSpace="preserve"><tspan style={tspanStyles} x="111.201" y="31.0554">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t37"]}</tspan></text>
                    <text id="toothnumber_t36" style={getToothNumberStylesFromClasses(toothClasses["t36"])} xmlSpace="preserve"><tspan style={tspanStyles} x="106.711" y="48.3261">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t36"]}</tspan></text>
                    <text id="toothnumber_t46" style={getToothNumberStylesFromClasses(toothClasses["t46"])} xmlSpace="preserve"><tspan style={tspanStyles} x="9.48398" y="48.3261">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t46"]}</tspan></text>
                    <text id="toothnumber_t45" style={getToothNumberStylesFromClasses(toothClasses["t45"])} xmlSpace="preserve"><tspan style={tspanStyles} x="14.4488" y="64.1911">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t45"]}</tspan></text>
                    <text id="toothnumber_t35" style={getToothNumberStylesFromClasses(toothClasses["t35"])} xmlSpace="preserve"><tspan style={tspanStyles} x="101.755" y="64.1911">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t35"]}</tspan></text>
                    <text id="toothnumber_t34" style={getToothNumberStylesFromClasses(toothClasses["t34"])} xmlSpace="preserve"><tspan style={tspanStyles} x="96.0543" y="77.9218">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t34"]}</tspan></text>
                    <text id="toothnumber_t44" style={getToothNumberStylesFromClasses(toothClasses["t44"])} xmlSpace="preserve"><tspan style={tspanStyles} x="21.1813" y="77.9218">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t44"]}</tspan></text>
                    <text id="toothnumber_t43" style={getToothNumberStylesFromClasses(toothClasses["t43"])} xmlSpace="preserve"><tspan style={tspanStyles} x="28.4703" y="88.9802">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t43"]}</tspan></text>
                    <text id="toothnumber_t33" style={getToothNumberStylesFromClasses(toothClasses["t33"])} xmlSpace="preserve"><tspan style={tspanStyles} x="88.898" y="88.9875">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t33"]}</tspan></text>
                    <text id="toothnumber_t32" style={getToothNumberStylesFromClasses(toothClasses["t32"])} xmlSpace="preserve"><tspan style={tspanStyles} x="80.3082" y="97.4496">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t32"]}</tspan></text>
                    <text id="toothnumber_t42" style={getToothNumberStylesFromClasses(toothClasses["t42"])} xmlSpace="preserve"><tspan style={tspanStyles} x="36.2828" y="97.904">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t42"]}</tspan></text>
                    <text id="toothnumber_t41" style={getToothNumberStylesFromClasses(toothClasses["t41"])} xmlSpace="preserve"><tspan style={tspanStyles} x="51.0035" y="102.657">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t41"]}</tspan></text>
                    <text id="toothnumber_t31" style={getToothNumberStylesFromClasses(toothClasses["t31"])} xmlSpace="preserve"><tspan style={tspanStyles} x="67.2848" y="102.657">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t31"]}</tspan></text>
                    <text id="toothnumber_t38" style={getToothNumberStylesFromClasses(toothClasses["t38"])} xmlSpace="preserve"><tspan style={tspanStyles} x="111.707" y="12.6298">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t38"]}</tspan></text>
                </g>
            </g>
        </svg>
    </StyledToothSet>    
)

const ToothSetTopPrimary = ({toothClasses={}, width=125, height=105, cssId='', numberingSystem="palmer"}) => (
    <StyledToothSet id={cssId} >
        <svg width={`${width}pt`} height={`${height}pt`} viewBox="0 0 126 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ToothDefs />
            <g id="upper">
                <g id="toothregions">
                    <path id="t53" style={getToothRegionStylesFromClasses(toothClasses["t53"])} fillRule="evenodd" clipRule="evenodd" d="M39.4847 21.3396C39.0556 23.1398 38.2011 24.7477 36.8655 26.056C35.7208 27.1764 34.3477 27.4107 32.9321 26.6651C31.422 25.8693 30.0546 24.9137 28.8554 23.7771C27.7738 22.7519 26.8274 21.5802 26.0523 20.2286C25.8747 19.9191 25.7227 19.592 25.5894 19.2612C24.4028 16.2999 25.5363 14.5704 28.7434 14.4476C29.1 14.4338 29.4578 14.4457 29.815 14.4457C31.5878 14.5892 33.3687 14.7095 35.0627 15.2083C35.7802 15.4189 36.4833 15.6908 37.1608 16.0718C39.2101 17.2247 40.0327 19.0393 39.4847 21.3396Z" />
                    <path id="t54" style={getToothRegionStylesFromClasses(toothClasses["t54"])} fillRule="evenodd" clipRule="evenodd" d="M31.3064 34.4592C31.135 34.8263 30.9486 35.1892 30.7309 35.5426C29.2615 37.9243 26.6148 39.0866 23.8618 37.7325C23.2513 37.4324 22.647 37.1291 22.0577 36.8051C20.898 36.1685 19.7964 35.4467 18.8286 34.4723C17.181 32.8125 17.1835 29.3781 18.671 27.1518C19.224 26.324 19.9753 25.6586 20.933 25.3196C22.2297 24.8615 23.5409 24.5207 24.7751 24.6015C26.0287 24.5758 27.1797 24.7444 28.2881 25.0163C28.8311 25.1498 29.3647 25.3058 29.8927 25.4794C31.4753 26.0001 32.615 27.5954 32.51 29.3242C32.4011 31.1062 32.0608 32.845 31.3064 34.4592Z" />
                    <path id="t55" style={getToothRegionStylesFromClasses(toothClasses["t55"])} fillRule="evenodd" clipRule="evenodd" d="M27.2484 43.2175C27.1264 44.5897 26.9143 45.9445 26.4977 47.2171C25.9904 48.7667 25.1784 50.1947 23.8435 51.3802C22.568 52.5131 21.0204 53.0952 19.3371 52.6202C18.2824 52.3226 17.2778 51.9285 16.3195 51.4466C14.897 50.731 13.579 49.8168 12.3704 48.6933C10.3768 46.8399 10.3092 43.5916 11.4853 41.1773C12.1277 39.8583 13.1361 38.7849 14.4172 38.3319C15.8459 37.8269 17.2891 37.5486 18.8792 37.481C19.8601 37.5781 20.8778 37.6539 21.8937 37.8187C22.5268 37.9215 23.1586 38.0587 23.7803 38.258C26.2619 39.0537 27.4786 40.6309 27.2484 43.2175Z" />
                    <path id="t51" style={getToothRegionStylesFromClasses(toothClasses["t51"])} fillRule="evenodd" clipRule="evenodd" d="M62.0055 4.04906C61.9348 4.49645 61.8647 4.96202 61.7853 5.4376C61.4143 7.64135 60.8226 10.0675 58.9015 12.0169C57.9388 12.9944 56.8053 13.2381 55.5105 12.9837C53.266 12.5432 51.4913 11.2217 49.7229 9.88834C48.9241 9.28555 48.2172 8.59316 47.5554 7.85503C47.2445 7.50789 46.9399 7.15323 46.6471 6.78793C45.2246 5.01402 45.3516 3.9864 47.1469 2.64986C48.0308 1.99131 49.0523 1.63916 50.0888 1.41045C52.1619 0.952407 54.2631 0.308263 56.3481 0.325808C57.8863 0.33834 59.5959 0.446115 61.0709 1.24064C61.8604 1.6661 62.0336 2.0972 62.0055 4.04906Z" />
                    <path id="t52" style={getToothRegionStylesFromClasses(toothClasses["t52"])} fillRule="evenodd" clipRule="evenodd" d="M46.768 14.2666C46.3232 16.3275 45.3017 17.5118 42.5174 17.273C39.9602 17.0544 37.8652 15.9647 36.028 14.4414C35.1622 13.7234 34.3534 12.9107 33.5809 12.0447C32.8396 11.2144 32.9941 10.2551 33.9312 9.26322C35.395 7.71426 37.2528 6.78752 39.1989 6.02182C40.749 5.41151 42.3197 4.94469 44.0312 5.17466C44.6893 5.26301 45.1316 5.55437 45.3912 6.12207C45.7484 6.90219 46.0386 7.69922 46.2713 8.51756C46.6617 9.89483 46.8856 11.3348 46.9751 12.8637C46.9206 13.2396 46.8775 13.7603 46.768 14.2666Z" />
                    <path id="t63" style={getToothRegionStylesFromClasses(toothClasses["t63"])} fillRule="evenodd" clipRule="evenodd" d="M85.8834 21.3396C86.3125 23.1398 87.167 24.7477 88.5026 26.056C89.6473 27.1764 91.0204 27.4107 92.436 26.6651C93.9461 25.8693 95.3135 24.9137 96.5127 23.7771C97.5943 22.7519 98.5407 21.5802 99.3158 20.2286C99.4934 19.9191 99.6454 19.592 99.7787 19.2612C100.965 16.2999 99.8318 14.5704 96.6247 14.4476C96.2681 14.4338 95.9103 14.4457 95.5531 14.4457C93.7803 14.5892 91.9994 14.7095 90.3054 15.2083C89.5879 15.4189 88.8848 15.6908 88.2073 16.0718C86.158 17.2247 85.3354 19.0393 85.8834 21.3396Z" />
                    <path id="t64" style={getToothRegionStylesFromClasses(toothClasses["t64"])} fillRule="evenodd" clipRule="evenodd" d="M94.061 34.4592C94.2324 34.8263 94.4188 35.1892 94.6365 35.5426C96.1059 37.9243 98.7526 39.0866 101.506 37.7325C102.116 37.4324 102.72 37.1291 103.31 36.8051C104.469 36.1685 105.571 35.4467 106.539 34.4723C108.186 32.8125 108.184 29.3781 106.696 27.1518C106.143 26.324 105.392 25.6586 104.434 25.3196C103.138 24.8615 101.827 24.5207 100.592 24.6015C99.3387 24.5758 98.1877 24.7444 97.0793 25.0163C96.5363 25.1498 96.0027 25.3058 95.4747 25.4794C93.8921 26.0001 92.7524 27.5954 92.8575 29.3242C92.9663 31.1062 93.3066 32.845 94.061 34.4592Z" />
                    <path id="t65" style={getToothRegionStylesFromClasses(toothClasses["t65"])} fillRule="evenodd" clipRule="evenodd" d="M98.1224 43.2175C98.2444 44.5897 98.4565 45.9445 98.8731 47.2171C99.3804 48.7667 100.192 50.1947 101.527 51.3802C102.803 52.5131 104.35 53.0952 106.034 52.6202C107.088 52.3226 108.093 51.9285 109.051 51.4466C110.474 50.731 111.792 49.8168 113 48.6933C114.994 46.8399 115.062 43.5916 113.886 41.1773C113.243 39.8583 112.235 38.7849 110.954 38.3319C109.525 37.8269 108.082 37.5486 106.492 37.481C105.511 37.5781 104.493 37.6539 103.477 37.8187C102.844 37.9215 102.212 38.0587 101.59 38.258C99.1089 39.0537 97.8922 40.6309 98.1224 43.2175Z" />
                    <path id="t61" style={getToothRegionStylesFromClasses(toothClasses["t61"])} fillRule="evenodd" clipRule="evenodd" d="M63.3711 4.04906C63.4418 4.49645 63.5119 4.96202 63.5913 5.4376C63.9623 7.64135 64.554 10.0675 66.4751 12.0169C67.4378 12.9944 68.5713 13.2381 69.8662 12.9837C72.1106 12.5432 73.8853 11.2217 75.6537 9.88834C76.4525 9.28555 77.1594 8.59316 77.8212 7.85503C78.1321 7.50789 78.4368 7.15323 78.7295 6.78793C80.152 5.01402 80.025 3.9864 78.2297 2.64986C77.3458 1.99131 76.3243 1.63916 75.2878 1.41045C73.2147 0.952407 71.1135 0.308263 69.0286 0.325808C67.4904 0.33834 65.7807 0.446115 64.3057 1.24064C63.5163 1.6661 63.343 2.0972 63.3711 4.04906Z" />
                    <path id="t62" style={getToothRegionStylesFromClasses(toothClasses["t62"])} fillRule="evenodd" clipRule="evenodd" d="M78.5969 14.2666C79.0416 16.3275 80.0631 17.5118 82.8474 17.273C85.4047 17.0544 87.4996 15.9647 89.3368 14.4414C90.2026 13.7234 91.0114 12.9107 91.784 12.0447C92.5253 11.2144 92.3707 10.2551 91.4337 9.26322C89.9699 7.71426 88.112 6.78752 86.166 6.02182C84.6159 5.41151 83.0451 4.94469 81.3336 5.17466C80.6755 5.26301 80.2333 5.55437 79.9737 6.12207C79.6165 6.90219 79.3262 7.69922 79.0935 8.51756C78.7032 9.89483 78.4793 11.3348 78.3898 12.8637C78.4442 13.2396 78.4874 13.7603 78.5969 14.2666Z" />
                </g>
                <g id="toothnumbers">
                    <text id="toothnumber_t55" style={getToothNumberStylesFromClasses(toothClasses["t55"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="14.5027" y="47.2995">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t55"]}</tspan></text>
                    <text id="toothnumber_t65" style={getToothNumberStylesFromClasses(toothClasses["t65"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="100.196" y="47.654">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t65"]}</tspan></text>
                    <text id="toothnumber_t54" style={getToothNumberStylesFromClasses(toothClasses["t54"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="22.0769" y="34.4094">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t54"]}</tspan></text>
                    <text id="toothnumber_t64" style={getToothNumberStylesFromClasses(toothClasses["t64"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="95.9578" y="33.6432">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t64"]}</tspan></text>
                    <text id="toothnumber_t53" style={getToothNumberStylesFromClasses(toothClasses["t53"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="29.2097" y="23.5695">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t53"]}</tspan></text>
                    <text id="toothnumber_t52" style={getToothNumberStylesFromClasses(toothClasses["t52"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="36.3972" y="14.457">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t52"]}</tspan></text>
                    <text id="toothnumber_t51" style={getToothNumberStylesFromClasses(toothClasses["t51"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="50.7175" y="9.90008">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t51"]}</tspan></text>
                    <text id="toothnumber_t61" style={getToothNumberStylesFromClasses(toothClasses["t61"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="65.6941" y="9.90008">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t61"]}</tspan></text>
                    <text id="toothnumber_t62" style={getToothNumberStylesFromClasses(toothClasses["t62"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="78.7195" y="14.457">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t62"]}</tspan></text>
                    <text id="toothnumber_t63" style={getToothNumberStylesFromClasses(toothClasses["t63"])} xmlSpace="preserve" ><tspan style={tspanStyles} x="87.8074" y="23.5695">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t63"]}</tspan></text>
                </g>
            </g>
        </svg>
    </StyledToothSet>
)


const ToothSetBottomPrimary = ({toothClasses={}, width=125, height=105, cssId='', numberingSystem="palmer"}) => (
    <StyledToothSet id={cssId} >
        <svg width={`${width}pt`} height={`${height}pt`} viewBox="0 0 126 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ToothDefs />
            <g id="Group 1261160704">
                <g id="toothregions">
                    <path id="t83" style={getToothRegionStylesFromClasses(toothClasses["t83"])} fillRule="evenodd" clipRule="evenodd" d="M39.798 84.4504C39.3689 82.6502 38.5144 81.0423 37.1789 79.734C36.0341 78.6136 34.661 78.3793 33.2454 79.1249C31.7354 79.9207 30.3679 80.8763 29.1687 82.0129C28.0872 83.038 27.1407 84.2098 26.3657 85.5614C26.188 85.8709 26.036 86.198 25.9028 86.5288C24.7161 89.4901 25.8496 91.2195 29.0568 91.3424C29.4133 91.3561 29.7711 91.3442 30.1283 91.3442C31.9011 91.2007 33.682 91.0804 35.376 90.5817C36.0935 90.3711 36.7966 90.0992 37.4741 89.7182C39.5234 88.5653 40.346 86.7506 39.798 84.4504Z" />
                    <path id="t84" style={getToothRegionStylesFromClasses(toothClasses["t84"])} fillRule="evenodd" clipRule="evenodd" d="M31.6197 71.3328C31.4483 70.9656 31.2619 70.6028 31.0442 70.2494C29.5748 67.8677 26.9281 66.7053 24.1751 68.0594C23.5646 68.3596 22.9603 68.6628 22.371 68.9868C21.2113 69.6234 20.1097 70.3452 19.142 71.3196C17.4943 72.9795 17.4968 76.4139 18.9843 78.6402C19.5373 79.4679 20.2886 80.1334 21.2463 80.4723C22.5431 80.9304 23.8542 81.2713 25.0884 81.1904C26.342 81.2161 27.493 81.0476 28.6015 80.7756C29.1444 80.6422 29.678 80.4861 30.206 80.3126C31.7886 79.7919 32.9284 78.1965 32.8233 76.4678C32.7144 74.6857 32.3741 72.9469 31.6197 71.3328Z" />
                    <path id="t85" style={getToothRegionStylesFromClasses(toothClasses["t85"])} fillRule="evenodd" clipRule="evenodd" d="M27.5617 62.5725C27.4397 61.2002 27.2277 59.8455 26.811 58.5729C26.3037 57.0233 25.4918 55.5953 24.1569 54.4098C22.8814 53.2769 21.3338 52.6948 19.6504 53.1697C18.5958 53.4674 17.5911 53.8615 16.6328 54.3434C15.2103 55.0589 13.8923 55.9731 12.6837 57.0966C10.6901 58.9501 10.6225 62.1984 11.7986 64.6127C12.441 65.9317 13.4494 67.0051 14.7305 67.4581C16.1593 67.9631 17.6024 68.2413 19.1925 68.309C20.1734 68.2119 21.1911 68.1361 22.207 67.9713C22.8401 67.8685 23.4719 67.7313 24.0937 67.532C26.5752 66.7362 27.7919 65.1591 27.5617 62.5725Z" />
                    <path id="t81" style={getToothRegionStylesFromClasses(toothClasses["t81"])} fillRule="evenodd" clipRule="evenodd" d="M62.3188 101.742C62.2481 101.295 62.1781 100.829 62.0986 100.354C61.7277 98.1498 61.1359 95.7236 59.2149 93.7743C58.2521 92.7968 57.1187 92.5531 55.8238 92.8075C53.5793 93.248 51.8046 94.5695 50.0362 95.9029C49.2374 96.5056 48.5305 97.198 47.8687 97.9362C47.5578 98.2833 47.2532 98.638 46.9604 99.0033C45.5379 100.777 45.6649 101.805 47.4602 103.141C48.3441 103.8 49.3656 104.152 50.4022 104.381C52.4752 104.839 54.5764 105.483 56.6614 105.465C58.1996 105.453 59.9092 105.345 61.3842 104.551C62.1737 104.125 62.347 103.694 62.3188 101.742Z" />
                    <path id="t82" style={getToothRegionStylesFromClasses(toothClasses["t82"])} fillRule="evenodd" clipRule="evenodd" d="M47.0813 91.5234C46.6366 89.4625 45.615 88.2782 42.8307 88.5169C40.2735 88.7356 38.1786 89.8253 36.3413 91.3485C35.4756 92.0666 34.6667 92.8793 33.8942 93.7453C33.1529 94.5755 33.3074 95.5349 34.2445 96.5268C35.7083 98.0757 37.5661 99.0025 39.5122 99.7682C41.0623 100.378 42.6331 100.845 44.3446 100.615C45.0026 100.527 45.4449 100.236 45.7045 99.6679C46.0617 98.8878 46.3519 98.0908 46.5846 97.2724C46.975 95.8952 47.1989 94.4552 47.2884 92.9263C47.234 92.5504 47.1908 92.0297 47.0813 91.5234Z" />
                    <path id="t73" style={getToothRegionStylesFromClasses(toothClasses["t73"])} fillRule="evenodd" clipRule="evenodd" d="M86.1967 84.4504C86.6259 82.6502 87.4804 81.0423 88.8159 79.734C89.9606 78.6136 91.3337 78.3793 92.7493 79.1249C94.2594 79.9207 95.6268 80.8763 96.826 82.0129C97.9076 83.038 98.854 84.2098 99.6291 85.5614C99.8067 85.8709 99.9587 86.198 100.092 86.5288C101.279 89.4901 100.145 91.2195 96.938 91.3424C96.5814 91.3561 96.2236 91.3442 95.8664 91.3442C94.0936 91.2007 92.3127 91.0804 90.6187 90.5817C89.9012 90.3711 89.1981 90.0992 88.5206 89.7182C86.4714 88.5653 85.6488 86.7506 86.1967 84.4504Z" />
                    <path id="t74" style={getToothRegionStylesFromClasses(toothClasses["t74"])} fillRule="evenodd" clipRule="evenodd" d="M94.3743 71.3328C94.5457 70.9656 94.7321 70.6028 94.9498 70.2494C96.4192 67.8677 99.0659 66.7053 101.819 68.0594C102.429 68.3596 103.034 68.6628 103.623 68.9868C104.783 69.6234 105.884 70.3452 106.852 71.3196C108.5 72.9795 108.497 76.4139 107.01 78.6402C106.457 79.4679 105.705 80.1334 104.748 80.4723C103.451 80.9304 102.14 81.2713 100.906 81.1904C99.6521 81.2161 98.5011 81.0476 97.3926 80.7756C96.8496 80.6422 96.316 80.4861 95.7881 80.3126C94.2054 79.7919 93.0657 78.1965 93.1708 76.4678C93.2796 74.6857 93.6199 72.9469 94.3743 71.3328Z" />
                    <path id="t75" style={getToothRegionStylesFromClasses(toothClasses["t75"])} fillRule="evenodd" clipRule="evenodd" d="M98.4358 62.5725C98.5577 61.2002 98.7698 59.8455 99.1864 58.5729C99.6937 57.0233 100.506 55.5953 101.841 54.4098C103.116 53.2769 104.664 52.6948 106.347 53.1697C107.402 53.4674 108.406 53.8615 109.365 54.3434C110.787 55.0589 112.105 55.9731 113.314 57.0966C115.307 58.9501 115.375 62.1984 114.199 64.6127C113.556 65.9317 112.548 67.0051 111.267 67.4581C109.838 67.9631 108.395 68.2413 106.805 68.309C105.824 68.2119 104.806 68.1361 103.79 67.9713C103.157 67.8685 102.526 67.7313 101.904 67.532C99.4222 66.7362 98.2056 65.1591 98.4358 62.5725Z" />
                    <path id="t71" style={getToothRegionStylesFromClasses(toothClasses["t71"])} fillRule="evenodd" clipRule="evenodd" d="M63.6845 101.742C63.7551 101.295 63.8252 100.829 63.9047 100.354C64.2756 98.1498 64.8674 95.7236 66.7884 93.7743C67.7511 92.7968 68.8846 92.5531 70.1795 92.8075C72.424 93.248 74.1986 94.5695 75.967 95.9029C76.7659 96.5056 77.4727 97.198 78.1346 97.9362C78.4455 98.2833 78.7501 98.638 79.0429 99.0033C80.4653 100.777 80.3384 101.805 78.543 103.141C77.6592 103.8 76.6376 104.152 75.6011 104.381C73.528 104.839 71.4268 105.483 69.3419 105.465C67.8037 105.453 66.0941 105.345 64.619 104.551C63.8296 104.125 63.6563 103.694 63.6845 101.742Z" />
                    <path id="t72" style={getToothRegionStylesFromClasses(toothClasses["t72"])} fillRule="evenodd" clipRule="evenodd" d="M78.9102 91.5234C79.3549 89.4625 80.3765 88.2782 83.1608 88.5169C85.718 88.7356 87.8129 89.8253 89.6502 91.3485C90.5159 92.0666 91.3248 92.8793 92.0973 93.7453C92.8386 94.5755 92.6841 95.5349 91.747 96.5268C90.2832 98.0757 88.4254 99.0025 86.4793 99.7682C84.9292 100.378 83.3584 100.845 81.6469 100.615C80.9889 100.527 80.5466 100.236 80.287 99.6679C79.9298 98.8878 79.6396 98.0908 79.4069 97.2724C79.0165 95.8952 78.7926 94.4552 78.7031 92.9263C78.7575 92.5504 78.8007 92.0297 78.9102 91.5234Z" />
                    <path id="tongue" style={tongueStyles} fillRule="evenodd" clipRule="evenodd" d="M83.4329 1.0673C89.5103 2.95961 92.9852 9.12675 92.3874 16.7819V16.8678C92.3953 17.3153 93.0501 63.2648 75.2384 73.3973L75.1333 73.4664C75.1333 73.4664 75.1289 73.4696 75.1231 73.4739C74.912 73.6315 71.5255 76.1583 64.264 76.1583C57.4435 76.1583 52.9469 73.8496 52.1778 73.4548C52.1545 73.4428 52.1347 73.4326 52.1183 73.4243C52.0639 73.3962 52.0137 73.3704 51.9604 73.3401C34.6958 63.3552 34.8907 19.0796 34.9113 16.8678C34.9118 16.8077 34.9097 16.7471 34.9049 16.6876C34.343 9.07621 37.8142 2.95119 43.8662 1.04147C46.0034 0.359231 48.2214 0.00884527 50.4527 0.000422524C54.6627 -0.0276533 58.7686 1.34413 62.2018 3.91363C62.6232 4.2292 63.1191 4.41282 63.6351 4.41282C64.1431 4.41282 64.6374 4.24829 65.0493 3.9316C67.3138 2.1909 73.9736 -1.9048 83.4329 1.0673ZM63.6971 47.7113C63.8681 47.7113 64.0069 47.5696 64.0069 47.3949V11.372C64.0069 11.1973 63.8681 11.0556 63.6971 11.0556C63.5261 11.0556 63.3873 11.1973 63.3873 11.372V47.3949C63.3873 47.5696 63.5261 47.7113 63.6971 47.7113Z" />
                </g>
                <g id="toothnumbers">
                    <text id="toothnumber_t85" style={getToothNumberStylesFromClasses(toothClasses["t85"])} xmlSpace="preserve"><tspan style={tspanStyles} x="14.4488" y="64.1911">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t85"]}</tspan></text>
                    <text id="toothnumber_t75" style={getToothNumberStylesFromClasses(toothClasses["t75"])} xmlSpace="preserve"><tspan style={tspanStyles} x="101.755" y="64.1911">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t75"]}</tspan></text>
                    <text id="toothnumber_t74" style={getToothNumberStylesFromClasses(toothClasses["t74"])} xmlSpace="preserve"><tspan style={tspanStyles} x="96.0543" y="77.9218">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t74"]}</tspan></text>
                    <text id="toothnumber_t84" style={getToothNumberStylesFromClasses(toothClasses["t84"])} xmlSpace="preserve"><tspan style={tspanStyles} x="21.1813" y="77.9218">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t84"]}</tspan></text>
                    <text id="toothnumber_t83" style={getToothNumberStylesFromClasses(toothClasses["t83"])} xmlSpace="preserve"><tspan style={tspanStyles} x="28.4703" y="88.9802">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t83"]}</tspan></text>
                    <text id="toothnumber_t73" style={getToothNumberStylesFromClasses(toothClasses["t73"])} xmlSpace="preserve"><tspan style={tspanStyles} x="88.898" y="88.9875">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t73"]}</tspan></text>
                    <text id="toothnumber_t72" style={getToothNumberStylesFromClasses(toothClasses["t72"])} xmlSpace="preserve"><tspan style={tspanStyles} x="80.3082" y="97.4496">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t72"]}</tspan></text>
                    <text id="toothnumber_t82" style={getToothNumberStylesFromClasses(toothClasses["t82"])} xmlSpace="preserve"><tspan style={tspanStyles} x="36.2828" y="97.904">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t82"]}</tspan></text>
                    <text id="toothnumber_t81" style={getToothNumberStylesFromClasses(toothClasses["t81"])} xmlSpace="preserve"><tspan style={tspanStyles} x="51.0035" y="102.657">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t81"]}</tspan></text>
                    <text id="toothnumber_t71" style={getToothNumberStylesFromClasses(toothClasses["t71"])} xmlSpace="preserve"><tspan style={tspanStyles} x="67.2848" y="102.657">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t71"]}</tspan></text>
                </g>
            </g>
        </svg>
    </StyledToothSet>    
)

export {ToothSetTop, ToothSetTopPrimary, ToothSetBottom, ToothSetBottomPrimary};
