import React from 'react';
import { Icon } from '@chakra-ui/react';

const ThreeVerticalDotsIcon = (props) => {
  const iconWidth = props?.width || 20;
  const iconHeight = props?.height || 20;
  return (
    <Icon 
      viewBox="0 0 20 20" 
      color={'#2E3D44'} 
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            d="M12 4.49952C12.0001 4.6965 11.9613 4.89157 11.886 5.07358C11.8107 5.25559 11.7002 5.42099 11.561 5.56032C11.4218 5.69965 11.2564 5.81019 11.0745 5.88563C10.8925 5.96108 10.6975 5.99994 10.5005 6C10.3035 6.00006 10.1084 5.96133 9.92642 5.886C9.74441 5.81068 9.57901 5.70024 9.43968 5.561C9.30035 5.42176 9.18981 5.25643 9.11437 5.07447C9.03893 4.89251 9.00006 4.69746 9 4.50048C8.99987 4.10266 9.15779 3.72108 9.439 3.43968C9.72021 3.15829 10.1017 3.00013 10.4995 3C10.8973 2.99987 11.2789 3.15778 11.5603 3.439C11.8417 3.72021 11.9999 4.10169 12 4.49952Z" 
            fill="currentColor"
        />
        <path 
            d="M10.4995 11.147C11.3277 11.147 11.999 10.4756 11.999 9.64747C11.999 8.81931 11.3277 8.14795 10.4995 8.14795C9.67136 8.14795 9 8.81931 9 9.64747C9 10.4756 9.67136 11.147 10.4995 11.147Z" 
            fill="currentColor"
        />
        <path 
            d="M10.4995 16.2959C11.3277 16.2959 11.999 15.6246 11.999 14.7964C11.999 13.9682 11.3277 13.2969 10.4995 13.2969C9.67136 13.2969 9 13.9682 9 14.7964C9 15.6246 9.67136 16.2959 10.4995 16.2959Z" 
            fill="currentColor"
        />
    </Icon>
  );
};
export default ThreeVerticalDotsIcon;
