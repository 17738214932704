import React from 'react';
import { 
    Box, Flex, HStack, Heading, Input, Select, Button, IconButton, Center, Text,
    // eslint-disable-next-line no-unused-vars
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, useDisclosure,
    Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl';
import { getQueryParams, useUserRequestCollection as useCollectionQuery } from '../api/getUserRequestCollection';

import { HeaderMenu } from '../components/HeaderMenu';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import PaginationNext from 'src/_images/icons/PaginationNext';
import { UserRequestForm } from '../components/UserRequestForm';
import TrashIcon from 'src/_images/icons/TrashIcon';
import { useDeleteRequest } from '../api/deleteUserRequest';

const COLLECTION_PAGE_KEY = "userRequests";

export const SearchSection = ({searchInput, setSearchInput, handleSearch}) => {
    const { formatMessage } = useIntl();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    return (
        <Box>     
            <Flex mt={'30px'}>
                <Input type="text" 
                    placeholder={formatMessage({id: "patientsListPage.patientSearch.button.search", defaultMessage: "Search"})}
                    value={searchInput} 
                    onKeyUp={handleKeyPress} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <Button 
                    variant={"outlined"}
                    onClick={handleSearch} 
                >
                    <FormattedMessage 
                        id="patientsListPage.patientSearch.button.search"
                        defaultMessage="Search"
                    />
                </Button>
            </Flex>
        </Box>
    )
} 

const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    return (
        <HStack spacing={3} >
            <IconButton 
                icon={<PaginationPrevious />} 
                isDisabled={!canPreviousPage}
                isLoading={isLoading}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <Select 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </Select>
            <IconButton 
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                isLoading={isLoading}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}

const actionAccessorFn = ({row, onDelete}) => {
    const request = row.original;
    const requestId = request.id;
    return (
        <Flex justify={'center'}>
            <IconButton
                icon={<TrashIcon color={'white'}/>} 
                variant="delete" 
                borderRadius={'7px'}
                size="sm" 
                onClick={() => onDelete(requestId)}
            />
        </Flex>
    )
}

const PageTable = ({data, isLoading, pagination, setPagination, onEditRow}) => {
    const { formatDate, formatMessage } = useIntl();
    const datetimeOptions = {
        hour: 'numeric', minute: 'numeric', second: 'numeric', month: 'short', day: 'numeric', year: 'numeric'
    }
    const definedStatusMessages = {
        'DMFT': formatMessage({
            id: 'adminPage.userRequest.type.text.dmft', defaultMessage: 'DMFT'
        }), 
        'GEN_DMFT': formatMessage({
            id: 'adminPage.userRequest.type.text.dmft', defaultMessage: 'DMFT (AI)'
        }), 
        'REPORT': formatMessage({
            id: 'adminPage.userRequest.type.text.report', defaultMessage: 'Report'
        }), 
        'GENERATIVE': formatMessage({
            id: 'adminPage.userRequest.type.text.generative', defaultMessage: 'Report (AI)'
        }), 
        'VIDEOCHAT': formatMessage({
            id: 'adminPage.userRequest.type.text.videochat', defaultMessage: 'Video Chat'
        }),
    }
    
    const columns = React.useMemo(
        () => [
            {
                header: 'Id',
                id: 'id',
                accessorKey: 'id',
            },
            {
                header: 'User',
                id: 'User',
                accessorFn: row => `${row.user?.email}`,
            },
            {
                header: 'User Profile',
                id: 'user_profile',
                accessorFn: row => `${row.user_profile?.first_name} - ${row.user_profile?.relationship}`,
            },
            {
                header: 'Created At',
                id: 'created_at',
                accessorFn: row => formatDate(row?.created_at, datetimeOptions),
            },
            {
                header: 'type',
                id: 'type',
                accessorFn: row => definedStatusMessages[row?.type] || '',
            },
            {
                header: 'Status',
                id: 'status',
                accessorKey: 'status',
            },
            {
                header: 'Actions',
                id: 'action',
                cell: ({row}) => actionAccessorFn({row, onDelete: onEditRow}),
            }
        ],
        []
    )

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.requests || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })

    return (
        <>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} _hover={{cursor: 'pointer', bg: '#F7F7F7'}}>
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Flex w="full" mt={'50px'} justify={'flex-end'}>
                <Pagination 
                    isLoading={isLoading}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageIndex={table.getState().pagination.pageIndex}
                    total={table.getPageCount()}
                    nextPage={table.nextPage}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                />
            </Flex>
        </>
    )
}

const NewUserRequestFormModal = ({
    isOpen,
    onClose,
    onSuccess
}) => {
    // email, password, first_name, last_name, date_of_birth, gender, user_type, country, categories, description, 
    // clini_id, account_role... 
    // clinic_name, branch, address, timezone, geopoint

    const handleOnSuccess = () => {
        onSuccess();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent minW="700px" bg="#f7f9fa">
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'adminPage.createUserRequestFormModal.title'}
                        defaultMessage={'Create User Request'}
                    />
              </Center>
            </ModalHeader>
            <ModalBody>
                <Heading as={'h2'}>
                    <FormattedMessage 
                        id={'adminPage.createUserRequestFormModal.title'}
                        defaultMessage={'User Request Details'}
                    />
                </Heading>
              <Box>
                <UserRequestForm
                  onSuccess={handleOnSuccess}
                  onClose={onClose}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
}

const PageHeader = ({onNewItemAdded}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <NewUserRequestFormModal isOpen={isOpen} onClose={onClose} onSuccess={onNewItemAdded} />
            <Flex justify={'space-between'} align={'end'}>
                <HeaderMenu page={COLLECTION_PAGE_KEY} />
                <Flex w={'full'} justify={'flex-end'}>
                    <Button variant="outline" onClick={onOpen}>
                        <FormattedMessage
                            id="admin.userRequestPage.button.newRequest"
                            defaultMessage="+ Create User Request"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

const ConfirmationDialog = ({ isOpen, onClose, userRequestId, handleClose, queryParams  }) => {
    const [ error, setError ] = React.useState(null);
    const deleteMutation = useDeleteRequest({id: userRequestId, queryParams})

    const handleDelete = async () => {
        try{
            const resp = await deleteMutation.mutateAsync();  
            handleClose && handleClose(resp);
            onClose();
        } catch (err){
            console.error(err);
            setError(err);
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'confirmationDialog.deleteRequest.title'}
                            defaultMessage='Delete User Request?'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        <FormattedMessage 
                            id={'confirmationDialog.deleteRequest.description'}
                            defaultMessage='Are you sure you want to delete the request? It will be permanently removed from the system.'
                        />
                    </Text>
                </ModalBody>
                <Flex w={'full'} justify={'center'} align={'center'}>
                    {error && <Text color="red">{error.message}</Text>}
                </Flex>
                <ModalFooter pb={'20px'}>
                    <Button 
                        isLoading={deleteMutation.isLoading}
                        variant={'delete'} 
                        mr={3} 
                        onClick={handleDelete}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.yesDelete'}
                            defaultMessage={'Yes, Delete'}
                        />
                    </Button>
                    <Button 
                        isDisabled={deleteMutation.isLoading}
                        variant="outlined" 
                        onClick={onClose}
                    >
                        <FormattedMessage 
                            id={'confirmationDialog.cancel'}
                            defaultMessage={'No, Cancel'} 
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}


export const UserRequestCollectionPage = () => {
    const PAGE_SIZE = 15;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    }) 
    const {data, isLoading, isFetching,refetch} = useCollectionQuery(
        getQueryParams({offset:pagination.pageIndex, pg_sz: pagination.pageSize})
    )
    const [ currentRequest, setCurrentRequest ] = React.useState(null);

    return (
        <Box
            minH="100vh"
            pt="102px"
            ml="90px"
            pr="20px"
            bg="#fff"
            pl="60px"
            pb={"100px"}
        >
            <ConfirmationDialog 
                isOpen={currentRequest != null} 
                userRequestId={currentRequest} 
                queryParams={getQueryParams({offset:pagination.pageIndex, pg_sz: pagination.pageSize})} 
                onClose={() => setCurrentRequest(null)} 
            /> 
            <PageHeader onNewItemAdded={refetch}/>
            <PageTable data={data}  onEditRow={setCurrentRequest} isLoading={isLoading || isFetching} pagination={pagination} setPagination={setPagination} />
        </Box>
    )
}