import { useMutation } from 'react-query';

import { queryClient } from 'src/_libs/react-query';
import { QUERY_KEY_NAMES } from './constants';
import { deleteUserRequest } from './services';

export const QUERY_KEY_NAME = 'Admin_DeleteUserRequest';

export const deleteQuery = ({ id }) => {
    //return axios.post(apiRoutes.appointmentsCreate, data);
    return deleteUserRequest(id)
};

export const useDeleteRequest = ({ id, queryParams }) => {
  return useMutation({
    mutationKey: [QUERY_KEY_NAMES.DELETE_USERREQUEST, { id }],
    onMutate: async () => {
      // cancel any ongoing queries
      await queryClient.cancelQueries([QUERY_KEY_NAMES.DELETE_USERREQUEST, { id }]);
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: (_data) => {
      // remove from user request query
      queryClient.setQueryData([QUERY_KEY_NAMES.GET_USERREQUEST_COLLECTION, queryParams], (oldData) => {
        const newData = (oldData.requests || []).filter((request) => request.id !== id);
        return {
            requests: newData,
        };
      })
      queryClient.invalidateQueries([QUERY_KEY_NAMES.GET_USERREQUEST_COLLECTION, { id }]);  

    },
    mutationFn: () => deleteQuery({id}),
  });
};
