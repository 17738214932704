/* eslint-disable no-unused-vars */
import { forEach } from 'lodash';
import React from 'react';
import {
    Canvg,
    presets
  } from 'canvg'
import domtoimage from 'dom-to-image';
import styled from 'styled-components';
import { Box, Button } from '@chakra-ui/react';

import { ToothSetBottom, ToothSetTop } from 'src/_components/ToothSet/ToothSetSplit';


const StyledToothChart = styled.div`
    width: 125pt;
    height: 105pt;
    background: transparent;
    font-family: 'DM Sans';
`;

const preset = presets.offscreen()

async function toPngUrl(svgElement) {
    //console.log(svgElement.innerHTML)
    //const serializer = new XMLSerializer();
    //const svgStr = serializer.serializeToString(svgElement);
    const scale = 3 // default, no other images, one comment. is total .5 MBs. x2: adds .6MB, x3 adds 1.5Mbs

    const svgStr = svgElement.innerHTML

    const canvas = new OffscreenCanvas(svgElement.clientWidth, svgElement.clientHeight)
    const ctx = canvas.getContext('2d')
    const v = await Canvg.fromString(ctx, svgStr, preset); //{dpi: 300})

    v.resize(svgElement.clientWidth * scale, svgElement.clientHeight * scale)
  
    // Render only first frame, ignoring animations and mouse.
    await v.render()
   
    const blob = await canvas.convertToBlob()
    //const pngUrl = URL.createObjectURL(blob)
  
    const pngUrl = await domtoimage.toPng(svgElement, {quality: 1})

    return pngUrl
  }


export const TestTeethChart = () => {

    const replaceSvgsForPDF = async () => {        
        //setIsReplacing(true);
        
        // convert the svg charts to images
        const upperSvg = document.getElementById('toothChartMaxilla');
        const lowerSvg = document.getElementById('toothChartMandible');

        // Using CANVG
        // Convert SVGs to PNGs and replace them in the DOM
        //const imagePromises = [upperSvg, lowerSvg].map(svg => createUrlFromSvg(svg));

        if (upperSvg.getElementsByTagName('img').length == 0){
            const upperUrl = await toPngUrl(upperSvg); 
            // Replace SVGs with IMG tags in the DOM
            const upperImg = document.createElement('img');
            upperImg.crossOrigin = 'anonymous';
            upperImg.crossorigin = 'anonymous';
            upperImg.src = upperUrl;
            forEach(upperSvg.childNodes, (node) => {
                node.style.display = 'none';
            })
            upperSvg.appendChild(upperImg)
        }

        if (lowerSvg.getElementsByTagName('img').length == 0){
            const lowerUrl = await toPngUrl(lowerSvg);
            const lowerImg = document.createElement('img');
            lowerImg.crossOrigin = 'anonymous';
            lowerImg.crossorigin = 'anonymous';
            lowerImg.src = lowerUrl;
            forEach(lowerSvg.childNodes, (node) => {
                node.style.display = 'none';
            })
            lowerSvg.appendChild(lowerImg)
        }

        //setIsReplacing(false);
    }

    const toothClasses = {
        't18': ['TOOTH'],
        't43': ['TOOTH', 'GUMS'],
        't41': ['GUMS'],
        't22': ['GUMS', 'OTHER'],
    }


    return (
        <Box p={'200px'}>
            <Button 
                onClick={replaceSvgsForPDF}
            >
                {'Download Image'}

            </Button>
            <StyledToothChart>
                <ToothSetTop 
                    cssId={'toothChartMaxilla'}
                    toothClasses={toothClasses}
                />
            </StyledToothChart>
                <StyledToothChart>
                    <ToothSetBottom
                        cssId={'toothChartMandible'}
                        toothClasses={toothClasses}
                />
            </StyledToothChart>
        </Box>
    )
}