import React from "react";

export const ToothDefs = () => {
    return (
        <defs>
            <pattern id="OTHER_TOOTH" patternUnits="userSpaceOnUse" x="-4" y="-4" width="8" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#367FFF"></rect>
                <rect x="4" width="4" height="8" fill="#DC4E41"></rect>
            </pattern>
            <pattern id="OTHERS_TOOTH" patternUnits="userSpaceOnUse" x="-4" y="-4" width="8" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#367FFF"></rect>
                <rect x="4" width="4" height="8" fill="#DC4E41"></rect>
            </pattern>

            <pattern id="GUMS_OTHER" patternUnits="userSpaceOnUse" x="-4" y="-4" width="8" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#367FFF"></rect>
                <rect x="4" width="4" height="8" fill="#FFA500"></rect>
            </pattern>
            <pattern id="GUMS_OTHERS" patternUnits="userSpaceOnUse" x="-4" y="-4" width="8" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#367FFF"></rect>
                <rect x="4" width="4" height="8" fill="#FFA500"></rect>
            </pattern>

            <pattern id="GUMS_TOOTH" patternUnits="userSpaceOnUse" x="-4" y="-4" width="8" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#DC4E41"></rect>
                <rect x="4" width="4" height="8" fill="#FFA500"></rect>
            </pattern>

            <pattern id="GUMS_OTHER_TOOTH" patternUnits="userSpaceOnUse" x="-6" y="-4" width="12" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#367FFF"></rect>
                <rect x="4" width="4" height="8" fill="#DC4E41"></rect>
                <rect x="8" width="4" height="8" fill="#FFA500"></rect>
            </pattern>
            <pattern id="GUMS_OTHERS_TOOTH" patternUnits="userSpaceOnUse" x="-6" y="-4" width="12" height="8" patternTransform="rotate(45)">
                <rect width="4" height="8" fill="#367FFF"></rect>
                <rect x="4" width="4" height="8" fill="#DC4E41"></rect>
                <rect x="8" width="4" height="8" fill="#FFA500"></rect>
                </pattern>
        </defs>
    )
}