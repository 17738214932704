import React from 'react';
import { Icon } from '@chakra-ui/react';

const TrashIcon = (props) => {
  const iconWidth = props?.width || 20;
  const iconHeight = props?.height || 21;
  return (
    <Icon 
      viewBox="0 0 20 21" 
      color={'#2E3D44'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M4 6.0293L16.2353 6.0293" stroke="currentColor"/>
        <path d="M5.5293 6.0293V17.4999H14.7058V6.0293" stroke="currentColor"/>
        <path d="M7.82324 6.02941V4.5H12.4115V6.02941" stroke="currentColor"/>
        <path d="M7.82324 8.70593V15.2059" stroke="currentColor"/>
        <path d="M10.1177 8.70593V15.2059" stroke="currentColor"/>
        <path d="M12.4121 8.70593V15.2059" stroke="currentColor"/>
    </Icon>
  );
};
export default TrashIcon;
